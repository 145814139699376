import { styled } from 'config/stitches.config'
import { motion } from 'framer-motion'

const Line = styled(motion.div, {
  height: '1px',
  width: '100%',
  variants: {
    theme: {
      default: { backgroundColor: '$black' },
      inverted: { backgroundColor: '$white' },
      lightGray: { backgroundColor: '$lightGray' }
    }
  },
  defaultVariants: { theme: 'default' }
})

export default Line
