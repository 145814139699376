import {
  styled, rem
} from 'config/stitches.config'

import TagList from '@/UI/Tag/List'
import Button from '@/UI/Button'

TagList.toString = () => '.TagList'

export const StyledJobsPreview = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  textDecoration: 'none',
  '.JobsCtaHeader': { '@md': { display: 'none' } }
})


export const StyledJobsPreviewInfos = styled('div', {
  display: 'inline-flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '@md': {
    justifyContent: 'flex-end',
    columnGap: '$3',
    transition: 'transform ease-in-out .3s',
    transform: 'translateX(64px)',
  },
  [`${TagList}`]: {
    width: 'auto',
    flexWrap: 'wrap'
  },

  [`${Button}`]: {
    transition: 'transform ease-in-out .3s, opacity ease-in-out .5s',
    transitionDelay: '0.1s, 0',
    transformOrigin: 'center right',
    opacity: 0,
    display: 'none',
    transform: 'scale(0)',
    '@md': { display: 'inherit' }
  }
})

export const StyledJobsPreviewContent = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '100%',
  marginTop: '$4',
  '@md': {
    marginTop: 0,
    paddingY: rem(40),
    paddingX: rem(48),

    '&:hover': {
      [`${StyledJobsPreviewInfos}`]: { transform: 'translateX(0)', },
      [`${Button}`]: {
        transform: 'scale(1)', opacity: 1,
      }
    }
  },

  '@lg': { flexDirection: 'row' },

})
