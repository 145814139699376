import React from 'react'

import ContentDivider from '@/ContentDivider'
import RichText from '@/UI/RichText'
import { StyledClientListPreview } from './styles'

const ClientListPreview = ({
  idx,
  title,
  description,
}) => {
  return (
    <StyledClientListPreview>
      <ContentDivider layout='column' label={idx < 9 ? `0${idx}` : idx} title={title} />
      <RichText
        className="Description"
        as="div"
        size={{
          '@initial': 'p1-mobile',
          '@md': 'p1',
        }}
        content={description} />
    </StyledClientListPreview>
  )
}

export default ClientListPreview
