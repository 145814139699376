import React from 'react'
import SectionContainer from '@/Section/Container'

import ListWithImages from '@/ListWithImages'


const CardsList = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) =>  (
  <SectionContainer
    theme={theme}
    sectionHeader={sectionHeader}
  >
    <ListWithImages layout={sectionContent.theme} items={sectionContent.clientsList} image={sectionContent.imagesWithTag} theme={theme} />
  </SectionContainer>
)

export default CardsList
