import { styled } from 'config/stitches.config'

import Text from '@/UI/Text'

export const StyledAnimatedParagraph = styled(Text, {
  'div': { transition: 'color 0.5s cubic-bezier(0.39, 0.58, 0.57, 1) 0s' },
  variants: {
    theme: {
      default: { color: '$lightGray' },
      inverted: { color: '#373737' },
    }
  }
})
