import React from 'react'

import SectionContainer from '@/Section/Container'


const SimpeContent = ({
  sectionGeneral: { theme },
  sectionHeader
}) => (
  <SectionContainer
    theme={theme}
    sectionHeader={sectionHeader}
  />
)

export default SimpeContent
