import React from 'react'

import withCustomCursor from 'hoc/withCustomCursor'


import TagList from '@/UI/Tag/List'
import CustomImage from '@/UI/Image'
import SeparatedIconCta from '@/UI/Cta/SeparatedIconCta'

import {
  StyledImage,
  StyledImageContent
} from './styles'

const Image = ({
  media,
  lazyload = true,
  cta,
  tags,
  theme,
  href,
  ...otherProps
}) => {
  return (
    <StyledImage
      href={href}
      {...otherProps}
    >
      <CustomImage
        {...media}
        lazyload={lazyload}
      />

      <StyledImageContent>
        {
          cta &&
          <SeparatedIconCta  {...cta} />
        }

        {
          tags &&
          <TagList tags={tags} theme={theme}/>
        }
      </StyledImageContent>
    </StyledImage>
  )
}

export default withCustomCursor(Image)
