import {
  styled,
  rem
} from 'config/stitches.config'


export const StyledHeroContainer = styled('div', {
  width: '100%',
  paddingTop: rem(192),
  paddingBottom: rem(40),

  '.TitleInCover': { textShadow: '0 2px 2px rgb(0 0 0 / 13%)', },
  'h1, h2': {
    position: 'relative',
    overflow: 'hidden',

    'span': { display: 'inline-block' }
  },
  '@md': { paddingTop: rem(280), }
})

export const StyledHero = styled('div', {
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',

  '@md': { alignItems: 'flex-start', },

  variants: {
    fullScreen: {
      true: {
        minHeight: '100vh',
        [StyledHeroContainer]: {
          paddingBottom: rem(120),
          '@md': { paddingBottom: rem(40), }
        }
      },
      false: {
        [StyledHeroContainer]: {
          paddingTop: `clamp(${rem(192)}, 40vh, 50vh)`,
          '@md': { paddingTop: rem(280) }
        },
      },
    }
  }
})

export const StyledHeroSubtitleWrapper = styled('div', { maxWidth: 900 })

export const StyledHeroSubtitle = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  columnGap: '$3',
  color: '$gray',
  transition: 'background-color ease-in-out .4s',
  variants: {
    withBackground: {
      true: {
        padding: '$1 $2 $1 $1',
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        br: '$small',
        backdropFilter: 'blur(8px)',
      }
    },
    showNews: { true: { width: '100%' } },
    hovered: {}
  },
  compoundVariants: [
    {
      showNews: true,
      hovered: true,
      css: {
        backgroundColor: '$white',
        'a': { textDecoration: 'underline' }
      }
    }
  ]
})


