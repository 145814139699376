import React, { useId } from 'react'
import { motion } from 'framer-motion'

import withCustomCursor from 'hoc/withCustomCursor'

import SectionHeader from '@/Section/Header'
import SectionContent from '@/Section/Content'

import { FadeIn } from 'animations'

import {
  StyledSectionContainer,
  StyledSectionContent
} from './styles'

const SectionContainer = ({
  theme,
  sectionHeader,
  children,
  footer,
  containerProps = {},
  ...rest
}) => (
  <StyledSectionContainer
    theme={theme}
    className='SectionContainer'
    {...containerProps}
  >

    <StyledSectionContent
      theme={theme}
      as={motion.div}
      initial="hidden"
      whileInView="show"
      variants={FadeIn({
        duration: 0.6,
        delay: 0.2,
        delayChildren: 0.4,
        staggerChildren: 0.4
      })}
      viewport={{ once: true }}
      {...rest}
      id={useId()}
    >
      {
        sectionHeader &&
        <SectionHeader {...sectionHeader} />
      }

      {
        children &&
        <SectionContent css={!sectionHeader && { marginTop: 0 }}>{children}</SectionContent>
      }
    </StyledSectionContent>


    {footer && footer}
  </StyledSectionContainer>
)

export default withCustomCursor(SectionContainer)
