import React from 'react'

import JobPreview from '@/Previews/JobPreview'

import { StyledJobs } from './styles'

const JobList = ({
  items,
  selectedCategory = null
}) => (
  items &&
  !!items.length &&
  <StyledJobs>

    {items.map((job, idx) => (
      <JobPreview key={idx} idx={idx} selectedCategory={selectedCategory} {...job} />
    ))}
  </StyledJobs>
)

export default JobList
