import arrowLeft from './arrow-left'
import arrowRight from './arrow-right'
import customerCare from './customer-care'
import endToEnd from './end-to-end'
import fullServiceC from './full-service-c'
import marketplaceOnesolution from './marketplace-onesolution'
import marketplaceSolution from './marketplace-solution'
import merchantRecords from './merchant-records'
import search from './search'

const iconsList = {
  arrowLeft,
  arrowRight,
  customerCare,
  endToEnd,
  fullServiceC,
  marketplaceOnesolution,
  marketplaceSolution,
  merchantRecords,
  search,
}

export default iconsList
