import { ENDPOINTS, } from 'config'

import { models } from '../models'

export const getApiUrl = () => {
  console.debug(' process.env.API_URL,',  process.env.API_URL,)
  return process.env.API_URL
}

export const fetchData = async (url, options) => {
  let response
  try {
    console.debug(
      '\x1b[32m',
      `Calling Url ${url}`
    )
    const request = await fetch(url, options)
    // console.log('REQUEST', request.headers)
    response = await request.json()
  } catch (error) {
    console.warn(error)
    if (process.env.NODE_ENV === 'development') {
      response = error
    }
  }
  return response
}

const getEntity = async ({
  entity,
  params = {},
}) => {
  const baseUrl = getApiUrl()

  let endpoint = ENDPOINTS[entity]
  const queryString = new URLSearchParams(params).toString()

  if (queryString) endpoint = `${endpoint}?${queryString}`

  const apiURL = `${baseUrl}${endpoint}`

  const data = await fetchData(apiURL)
  data.entity = entity

  return data
}

export const getForm = async (formID) => {
  if (!formID) throw new Error('formID is required')
  const baseUrl = getApiUrl()
  const apiURL = `${baseUrl}${ENDPOINTS.forms}/${formID}`
  const { data } = await fetchData(apiURL)

  if (!data) return {}

  return data
}

export const getMenus = async () => {
  const baseUrl = getApiUrl()
  const apiURL = `${baseUrl}${ENDPOINTS.menus}`
  const data = await fetchData(apiURL)
  return data
}


const getFooter = async (locale) => {
  const data = await getEntity({
    entity: 'footer', params: { locale }
  })

  const footerData = await models.Footer(data)
  return footerData
}

const getHeaderMenu = async (locale) => {
  const baseUrl = getApiUrl()
  const allMenusURL = `${baseUrl}${ENDPOINTS.navigation}`

  const menusData = await fetchData(allMenusURL)

  if (menusData.error) return {}

  const filteredMenuByLocale = menusData.find(menu => {
    const menuSlugExploded = menu.slug.split('-')
    return menuSlugExploded[0] === locale
  })

  if (!filteredMenuByLocale) return {}

  const menuURL = `${baseUrl}${ENDPOINTS.navigationSingle}/${filteredMenuByLocale.slug}`
  const menuData =  await fetchData(menuURL)

  const headerMenuData = await models.MainMenu(menuData)
  return headerMenuData
}

const getHeaderFooterData = async ({ locale }) => {
  const [
    headerMenu,
    footerData
  ] = await Promise.all([
    getHeaderMenu(locale),
    getFooter(locale)
  ])

  return {
    headerMenu,
    footerData
  }
}


const getOptions = async ({ locale }) => {
  const data = await getEntity({
    entity: 'options', params: { locale }
  })

  if (!data.data) return {}

  const options = await models.Options(data)

  return options
}

const getRedirects =  async () => {
  const baseUrl = getApiUrl()
  const apiURL = `${baseUrl}${ENDPOINTS.redirects}`
  const data = await fetchData(apiURL)

  if (!data.length === 0) return {}

  return data
}

const getPageData = async ({
  entity,
  slug = '',
  locale = 'it',
  isHomepage = false
}) => {
  const baseUrl = getApiUrl()

  const options = await getOptions({ locale })
  let apiURL =  `${baseUrl}${ENDPOINTS[entity]}`
  apiURL = !isHomepage ? `${apiURL}?filters[slug]=${slug}&locale=${locale}` : apiURL.replace('[locale]', locale)

  const data = await fetchData(apiURL)
  data.options = options

  return data
}

export {
  getPageData,
  getEntity,
  getHeaderFooterData,
  getOptions,
  getRedirects,
  models,
}
