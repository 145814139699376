import React from 'react'

import withCustomCursor from 'hoc/withCustomCursor'

import Icon from '@/UI/Icon'
import Text from '@/UI/Text'

import {
  StyledServicePreview,
  StyledServicePreviewContainer,
  StyledServicePreviewContent
} from './styles'

const ServicePreview = ({
  link,
  icon,
  title,
  excerpt,
  ...otherProps
}) => (
  <StyledServicePreview {...otherProps}>
    <StyledServicePreviewContainer
      className="ServicePreviewLink"
      {...link}>

      {
        icon &&
        <Icon name={icon} />
      }

      <StyledServicePreviewContent>
        <Text as="strong" size={{
          '@initial': 'subtitle-1-mobile',
          '@md': 'subtitle-1'
        }}
        >
          {title}
        </Text>
        <Text
          as="p"
          size={{
            '@initial': 'p1-mobile',
            '@md': 'p1'
          }}
        >
          {excerpt}
        </Text>
      </StyledServicePreviewContent>
    </StyledServicePreviewContainer>
  </StyledServicePreview>
)

export default withCustomCursor(ServicePreview)
