import React, { useContext } from 'react'
import { GeneralContext } from 'contexts/General'
import { StyledLogo } from './styles'

const Logo = (props) => {
  const {
    options,
    page
  } = useContext(GeneralContext)

  return (
    <StyledLogo href='/' darkMode={page.pageDarkTheme} aria-label={options.company} {...props}>
      <svg width="120" height="23" viewBox="0 0 120 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M33.105 13.7374C33.105 11.4804 35.0799 9.45898 37.3834 9.45898C39.4978 9.45898 40.958 10.8231 40.958 12.8445C40.958 15.1015 39.1258 17.0764 36.6796 17.0764C34.6117 17.0764 33.105 15.6658 33.105 13.7374Z" fill="#E17020"/>
          <path d="M120 22.2012H113.201L108.194 8.24988L103.153 22.2012H96.4778L105.422 -3.8147e-06H111.089L120 22.2012Z" fill="#4D4D4F"/>
          <path d="M86.2035 22.2012H79.4015L74.3945 8.24988L69.3565 22.2012H62.6785L71.6228 -3.8147e-06H77.2902L86.2035 22.2012Z" fill="#4D4D4F"/>
          <path d="M42.9512 0.158119H51.4863C59.3269 0.158119 63.8937 4.69385 63.8937 11.0526V11.1146C63.8937 17.4764 59.2649 22.1981 51.3623 22.1981H42.9543V0.158119H42.9512ZM49.0588 5.54333V16.785H51.5452C55.2283 16.785 57.6528 14.7698 57.6528 11.2107V11.1487C57.6528 7.62053 55.2283 5.54333 51.5452 5.54333H49.0588Z" fill="#4D4D4F"/>
          <path d="M88.6278 5.50923H82.0459V0.158119H101.351V5.50923H94.7385V22.2012H88.6278V5.50923Z" fill="#4D4D4F"/>
          <path d="M25.7014 6.15098H17.6716L18.7567 -3.8147e-06H40.9579L39.8728 6.15098H31.8121L28.9815 22.2012H22.8708L25.7014 6.15098Z" fill="#E17020"/>
          <path d="M15.3027 5.32008C15.3957 4.79613 15.8917 4.37449 16.4157 4.37449C16.9396 4.37449 17.2899 4.79923 17.1938 5.32008C17.1008 5.84403 16.6048 6.26567 16.0808 6.26567C15.5569 6.26567 15.2097 5.84093 15.3027 5.32008Z" fill="#E17020"/>
          <path d="M15.6779 3.18709C15.7709 2.66314 16.267 2.2415 16.7909 2.2415C17.3149 2.2415 17.6652 2.66314 17.5691 3.18709C17.4761 3.71104 16.98 4.13268 16.4561 4.13268C15.9321 4.13268 15.5849 3.70794 15.6779 3.18709Z" fill="#E17020"/>
          <path d="M16.0439 1.10371C16.1369 0.579759 16.636 0.158119 17.1569 0.158119C17.6777 0.158119 18.0281 0.58286 17.9382 1.10371C17.8451 1.62766 17.346 2.0493 16.822 2.0493C16.2981 2.0493 15.9509 1.62766 16.0439 1.10371Z" fill="#E17020"/>
          <path d="M13.1202 5.3201C13.2039 4.84886 13.6504 4.46752 14.1216 4.46752C14.5929 4.46752 14.906 4.84886 14.8223 5.3201C14.7417 5.79135 14.2921 6.17268 13.8209 6.17268C13.3496 6.17268 13.0365 5.79135 13.1202 5.3201Z" fill="#E17020"/>
          <path d="M13.4956 3.18709C13.5762 2.71585 14.0288 2.33451 14.4969 2.33451C14.9651 2.33451 15.2813 2.71585 15.1976 3.18709C15.1139 3.65834 14.6675 4.03967 14.1962 4.03967C13.725 4.03967 13.4087 3.65834 13.4956 3.18709Z" fill="#E17020"/>
          <path d="M13.8612 1.10371C13.9449 0.632465 14.3944 0.251129 14.8626 0.251129C15.3307 0.251129 15.6501 0.632465 15.5663 1.10371C15.4826 1.57495 15.0331 1.95629 14.5649 1.95629C14.0968 1.95629 13.7806 1.57495 13.8612 1.10371Z" fill="#E17020"/>
          <path d="M10.9934 5.3201C11.0679 4.89536 11.474 4.55433 11.8956 4.55433C12.3173 4.55433 12.6025 4.89846 12.5281 5.3201C12.4537 5.74484 12.0475 6.08587 11.6228 6.08587C11.1981 6.08587 10.919 5.74174 10.9934 5.3201Z" fill="#E17020"/>
          <path d="M11.3687 3.18711C11.4431 2.76547 11.8461 2.42133 12.2709 2.42133C12.6956 2.42133 12.9777 2.76547 12.9033 3.18711C12.8289 3.61185 12.4228 3.95288 12.0011 3.95288C11.5795 3.95288 11.2974 3.60875 11.3718 3.18711" fill="#E17020"/>
          <path d="M11.7374 1.10371C11.8118 0.678965 12.2149 0.337933 12.6365 0.337933C13.0581 0.337933 13.3434 0.682065 13.269 1.10371C13.1946 1.52845 12.7915 1.86948 12.3668 1.86948C11.942 1.86948 11.6599 1.52535 11.7374 1.10371Z" fill="#E17020"/>
          <path d="M8.7552 5.32007C8.82341 4.93874 9.18615 4.62871 9.56748 4.62871C9.94882 4.62871 10.203 4.93874 10.1348 5.32007C10.0697 5.70141 9.70389 6.01144 9.32256 6.01144C8.94122 6.01144 8.687 5.70141 8.7552 5.32007Z" fill="#E17020"/>
          <path d="M9.13029 3.18709C9.19849 2.80575 9.56123 2.49572 9.94256 2.49572C10.3239 2.49572 10.5781 2.80575 10.513 3.18709C10.4448 3.56842 10.0821 3.87845 9.70074 3.87845C9.3194 3.87845 9.06518 3.56842 9.13029 3.18709Z" fill="#E17020"/>
          <path d="M9.49893 1.1037C9.56404 0.722368 9.92987 0.412338 10.3112 0.412338C10.6925 0.412338 10.9468 0.722368 10.8817 1.1037C10.8135 1.48504 10.4507 1.79507 10.0694 1.79507C9.68805 1.79507 9.43382 1.48504 9.50203 1.1037" fill="#E17020"/>
          <path d="M6.57262 5.32013C6.63152 4.97909 6.96015 4.69697 7.30119 4.69697C7.64222 4.69697 7.87474 4.97599 7.81274 5.32013C7.75383 5.66426 7.4283 5.94329 7.08417 5.94329C6.74003 5.94329 6.51061 5.66426 6.57262 5.32013Z" fill="#E17020"/>
          <path d="M6.94762 3.18709C7.00652 2.84606 7.33516 2.56393 7.67929 2.56393C8.02342 2.56393 8.24974 2.84296 8.19084 3.18709C8.12883 3.53123 7.8033 3.81025 7.45917 3.81025C7.11503 3.81025 6.88561 3.53123 6.94762 3.18709Z" fill="#E17020"/>
          <path d="M7.31645 1.10371C7.37845 0.762679 7.70398 0.480553 8.04502 0.480553C8.38605 0.480553 8.61857 0.759579 8.55966 1.10371C8.50076 1.44474 8.17213 1.72687 7.82799 1.72687C7.48386 1.72687 7.25754 1.44784 7.31645 1.10371Z" fill="#E17020"/>
          <path d="M4.30603 5.32011C4.36183 5.01318 4.65636 4.76206 4.96329 4.76206C5.27022 4.76206 5.47794 5.01318 5.42523 5.32011C5.36943 5.63014 5.0749 5.87816 4.76797 5.87816C4.46104 5.87816 4.25332 5.63014 4.30603 5.32011Z" fill="#E17020"/>
          <path d="M4.68129 3.18708C4.73399 2.88015 5.03162 2.62903 5.33855 2.62903C5.64548 2.62903 5.8532 2.88015 5.79739 3.18708C5.74159 3.49711 5.45016 3.74823 5.14013 3.74823C4.8301 3.74823 4.62548 3.49711 4.68129 3.18708Z" fill="#E17020"/>
          <path d="M5.04716 1.1037C5.10297 0.796771 5.3975 0.545647 5.70443 0.545647C6.01136 0.545647 6.21908 0.796771 6.16327 1.1037C6.10746 1.41373 5.81294 1.66175 5.50601 1.66175C5.19908 1.66175 4.99136 1.41373 5.04406 1.1037" fill="#E17020"/>
          <path d="M2.09873 5.3201C2.14834 5.04418 2.41496 4.81786 2.69089 4.81786C2.96681 4.81786 3.15593 5.04418 3.10633 5.3201C3.05672 5.59603 2.7901 5.82235 2.51417 5.82235C2.23824 5.82235 2.04913 5.59603 2.09873 5.3201Z" fill="#E17020"/>
          <path d="M2.47361 3.18709C2.52321 2.91117 2.78984 2.68484 3.06576 2.68484C3.34169 2.68484 3.52771 2.91117 3.4812 3.18709C3.4316 3.46302 3.16807 3.68934 2.88905 3.68934C2.61002 3.68934 2.424 3.46302 2.47671 3.18709" fill="#E17020"/>
          <path d="M2.8428 1.10371C2.8924 0.827785 3.15593 0.601463 3.43495 0.601463C3.71398 0.601463 3.8969 0.827785 3.84729 1.10371C3.79769 1.38274 3.53106 1.60596 3.25514 1.60596C2.97921 1.60596 2.79009 1.37964 2.8397 1.10371" fill="#E17020"/>
          <path d="M0.00621431 5.32011C0.0496184 5.06899 0.288341 4.86747 0.539465 4.86747C0.790589 4.86747 0.958005 5.06899 0.9115 5.32011C0.868096 5.57123 0.629374 5.77275 0.37825 5.77275C0.127126 5.77275 -0.0371898 5.57123 0.00621431 5.32011Z" fill="#E17020"/>
          <path d="M0.384223 3.18712C0.427627 2.93599 0.66635 2.73447 0.917474 2.73447C1.1686 2.73447 1.33601 2.93599 1.28951 3.18712C1.24611 3.43824 1.00738 3.63976 0.756259 3.63976C0.505135 3.63976 0.337719 3.43514 0.384223 3.18712Z" fill="#E17020"/>
          <path d="M0.750111 1.1037C0.793515 0.852574 1.03224 0.651054 1.28336 0.651054C1.53449 0.651054 1.7019 0.852574 1.6554 1.1037C1.61199 1.35482 1.37327 1.55634 1.12215 1.55634C0.871022 1.55634 0.706707 1.35172 0.750111 1.1037Z" fill="#E17020"/>
        </g>
      </svg>
    </StyledLogo>
  )
}

export default Logo
