import React, {
  useContext,
  useEffect,
  useState,
} from 'react'

import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import { MenuContext } from 'contexts/Menu'
import Logo from '@/Logo'
import HamburgerMenu from '@/Buttons/HamburgerMenu'
import SearchButton from '@/Buttons/SearchButton'
import Container from '@/UI/Container'

const MenuModal = dynamic(() => import('@/Modals/MenuModal'), { ssr: false, })

import {
  StyledHeader,
  StyledHeaderContainer
} from './styles'

const Header = () => {
  const [theme, setTheme] = useState('default')
  const {
    menuOpen,
    menuChangeHandler
  } = useContext(MenuContext)

  const router = useRouter()

  useEffect(() => {
    let ScrollTrigger
    const mainSections = [...document.querySelectorAll('main section')]
    const footer = document.querySelector('.Footer')
    let sections = [...mainSections]
    if (footer) sections = [...sections, footer]

    const initTriggers = async () => {
      ScrollTrigger = (await import('gsap/dist/ScrollTrigger')).ScrollTrigger
      sections.map((item) => {
        ScrollTrigger.create({
          trigger: item,
          start: 'top 30',
          end: 'bottom 30',
          onToggle: self => {
            const themeToApply = self.trigger.dataset && self.trigger.dataset.theme && self.trigger.dataset.theme !== 'theme' ? self.trigger.dataset.theme : 'default'
            setTheme(themeToApply)
          }
        })
      })
    }

    initTriggers()
    return () => {
      setTheme('default')
      if (ScrollTrigger) {
        const triggers = ScrollTrigger.getAll()
        triggers.forEach(trigger => trigger.kill())
      }
    }
  }, [router.asPath, router.locale])

  return (
    <StyledHeader isOpen={menuOpen} theme={theme}>
      <Container>
        <StyledHeaderContainer>
          <Logo />
          <HamburgerMenu
            triggeredTheme={theme}
            menuOpen={menuOpen}
            menuChangeHandler={menuChangeHandler}
            cursor={{ type: 'hidden' }} />
          <SearchButton cursor={{ type: 'scaleUp' }} />
        </StyledHeaderContainer>
      </Container>
      <MenuModal />
    </StyledHeader>
  )
}

export default Header
