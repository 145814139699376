import { styled } from 'config/stitches.config'

const Text = styled('span', {
  lineHeight: '1',
  margin: '0',
  fontWeight: 400,
  display: 'block',
  variants: {
    size: {
      'footer': {
        fontSize: '$footer',
        lineHeight: '$footer'
      },
      'sub-desk': { fontSize: '$sub-desk' },
      'p3-mid': {
        fontSize: '$p3-mid', lineHeight: '$p3-mid'
      },
      'p1-mid': {
        fontSize: '$p1-mid', lineHeight: '$p1-mid'
      },
      'p1': {
        fontSize: '$p1',
        lineHeight: '$p1'
      },
      'subtitle-2': {
        fontSize: '$subtitle-2',
        lineHeight: '$subtitle-2'
      },
      'subtitle-1': {
        fontSize: '$subtitle-1',
        lineHeight: '$subtitle-1'
      },
      'h3': {
        fontSize: '$h3',
        lineHeight: '$h3'
      },
      'h2': {
        fontSize: '$h2',
        lineHeight: '$h2'
      },
      'h1': { fontSize: '$h1' },
      'h1-mid': { fontSize: '$h1-mid' },
      'sliding-txt': {
        fontSize: '$sliding-txt',
        lineHeight: '$sliding-txt'
      },
      'stats': {
        fontSize: '$stats',
        lineHeight: '$stats'
      },

      'sub-2-mobile': { fontSize: '$sub-2-mobile' },
      'sub-mobile': { fontSize: '$sub-mobile' },
      'p3-mid-mobile': {
        fontSize: '$p3-mid-mobile',
        lineHeight: '$p3-mid-mobile'
      },
      'p2-mid-mobile': {
        fontSize: '$p2-mid-mobile',
        lineHeight: '$p2-mid-mobile'
      },
      'p2-mobile': { fontSize: '$p2-mobile' },
      'p1-mid-mobile': {
        fontSize: '$p1-mid-mobile',
        lineHeight: '$p1-mid-mobile'
      },
      'p1-mobile': {
        fontSize: '$p1-mobile',
        lineHeight: '$p1-mobile'
      },
      'subtitle-2-mobile': {
        fontSize: '$subtitle-2-mobile',  lineHeight: '$subtitle-2-mobile'
      },
      'subtitle-1-mobile': {
        fontSize: '$subtitle-1-mobile', lineHeight: 'subtitle-1-mobile'
      },
      'h3-mobile': {
        fontSize: '$h3-mobile', lineHeight: '$h3-mobile'
      },
      'h2-mobile': {
        fontSize: '$h2-mobile',
        lineHeight: '$h2-mobile'
      },
      'h1-mobile': { fontSize: '$h1-mobile' },
      'h1-mid-mobile': { fontSize: '$h1-mid-mobile' },
      'sliding-txt-mobile': {
        fontSize: '$sliding-txt-mobile',
        lineHeight: '$sliding-txt-mobile'
      },
      'stats-mobile': {
        fontSize: '$stats-mobile',
        lineHeight: '$stats-mobile'
      },
    }
  },
  defaultVariants: { size: 'p1', }
})


export default Text
