import React from 'react'

import CustomImage from '@/Image'
import Heading from '@/UI/Heading'
import Text from '@/UI/Text'

import ContentDivider from '@/ContentDivider'

import {
  StyledNewsPreview,
  StyledNewsPreviewContainer,
  StyledNewsPreviewContent,
} from './styles'


const NewsPreview = ({
  idx,
  link,
  date,
  preview,
}) => (
  <StyledNewsPreview>
    <ContentDivider
      label={idx < 9 ? `0${idx + 1}` : idx + 1}
      lineProps={{
        initial: { transform: 'scale(0)' },
        animate: { transform: 'scale(1)' },
      }}
    />

    <StyledNewsPreviewContainer {...link}>
      {
        preview?.title &&
        <StyledNewsPreviewContent>
          <Text as="span" size={{
            '@initial': 'sub-desk-mobile',
            '@md': 'sub-desk'
          }}>{date}</Text>
          <Heading as="strong" size="s2" dangerouslySetInnerHTML={{ __html: preview.title }}/>
        </StyledNewsPreviewContent>

      }
      {
        preview?.mediaWithTag &&
        <CustomImage className="NewsPreview_image" {...preview.mediaWithTag} as="div" />
      }

    </StyledNewsPreviewContainer>
  </StyledNewsPreview>
)

export default NewsPreview
