import React from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'

const getMetaImage = (image, facebook, twitter) => {
  const defaultImage = image && image.data ? image.data.attributes.url : null

  const socialImages = {
    facebook: !!facebook && facebook.image && facebook.image.data ? facebook.image.data.attributes.url : defaultImage,
    twitter: !!twitter && facebook.image && twitter.image.data ? twitter.image.data.attributes.url : defaultImage,
  }

  return { ...socialImages }
}


const Metadata = ({
  defaultSeo = {},
  metaSocial = []
}) => {
  const {
    asPath, locale, defaultLocale
  } = useRouter()

  const canonicalURL = `${process.env.NEXT_PUBLIC_BASE_URL}${locale !== defaultLocale ? `/${locale}` : ''}${asPath}`

  const {
    title,
    description,
    image
  } = defaultSeo


  const socials = metaSocial.reduce((acc, curr) => {
    acc[curr.socialNetwork.toLowerCase()] = { ...curr }
    return acc
  }, {})

  const images = getMetaImage(image, socials.facebook, socials.twitter)

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      {!!canonicalURL && <link rel="canonical" href={canonicalURL} />}

      {
        socials.facebook &&
        <>
          {!!canonicalURL && <meta property="og:url" content={canonicalURL} />}
          <meta property="og:title" content={socials.facebook.title} />
          <meta property="og:description" content={socials.facebook.description} />
          <meta property="og:type" content="website" />
        </>
      }

      {
        socials.twitter &&
        <>
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:title" content={socials.twitter.title} />
          <meta property="twitter:description" content={socials.twitter.description} />
        </>
      }

      { images.facebook && <meta property="og:image" content={images.facebook} /> }
      { images.twitter && <meta property="twitter:image" content={images.twitter} /> }

      <meta id="seodata-metatag" httpEquiv="content-language" content={locale} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff"></meta>

      <link rel="manifest" href="/assets/images/favicon/site.webmanifest" />
      <link rel="apple-touch-icon" sizes="180x180" href="/assets/images/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/assets/images/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/assets/images/favicon/favicon-16x16.png" />

    </Head>
  )
}

export default Metadata
