import React from 'react'

const search = () => (
  <svg width="23" height="23" viewBox="0 0 23 23"  xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5 1C5.25329 1 1 5.25329 1 10.5C1 15.7467 5.25329 20 10.5 20C15.7467 20 20 15.7467 20 10.5C20 5.25329 15.7467 1 10.5 1ZM0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5C21 16.299 16.299 21 10.5 21C4.70101 21 0 16.299 0 10.5Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22.1465 22.8536L17.1465 17.8536L17.8536 17.1465L22.8536 22.1465C23.0489 22.3417 23.0489 22.6583 22.8536 22.8536C22.6583 23.0489 22.3417 23.0489 22.1465 22.8536Z"/>
  </svg>

)

export default search
