
import React, { forwardRef } from 'react'
import Link from 'next/link'
import Icon from '@/UI/Icon'

import { StyledCta } from './styles'

const ICON_CLASSNAME = 'Cta-icon'

const DefaultCta = (props, forwardedRef) => {
  const {
    icon = null,
    iconPosition = 'right',
    children
  } = props

  return (
    <StyledCta
      as={Link}
      {...props}
      ref={forwardedRef}
    >
      {
        icon &&
        iconPosition === 'left' &&
        <Icon className={ICON_CLASSNAME} name={icon} />
      }

      {
        children &&
        <span>
          {children}
        </span>
      }

      {
        icon &&
        iconPosition === 'right' &&
        <Icon className={ICON_CLASSNAME} name={icon} />
      }
    </StyledCta>
  )
}

export default forwardRef(DefaultCta)
