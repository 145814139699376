import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import { normalize } from 'stitches-normalize-css'

import { globalCss } from 'config/stitches.config'
import globals from 'theme/globals'

import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-creative'

import GeneralProvider from 'contexts/General'
import GTMProvider from 'contexts/GTM'
import MenuProvider from 'contexts/Menu'
import MouseProvider from 'contexts/Mouse'

import {
  Header,
  Footer
} from 'sections'

import Metadata from '@/Metadata'
import FooterScripts from '@/FooterScripts'

const DynamicCursor = dynamic(() =>  import('@/Cursor'), { ssr: false })
const DynamicIndex = dynamic(() =>  import('@/Index'), { ssr: false })

function MyApp({
  Component,
  pageProps = null
}) {
  const globalStyles = globalCss(...normalize, globals)
  globalStyles()

  const {
    asPath, locale
  } = useRouter()

  if (process.env.NEXT_PUBLIC_ENV !== 'production') {
    console.debug('- - APP - -', pageProps)
  }

  useEffect(() => {
    let lenis
    const startLenis = async () => {
      const app = document.querySelector('#__next')
      const Lenis = (await import('@studio-freight/lenis')).default

      lenis = new Lenis({
        content: app,
        duration: 1.2,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
        direction: 'vertical', // vertical, horizontal
        gestureDirection: 'vertical', // vertical, horizontal, both
        smooth: true,
        mouseMultiplier: 1,
        smoothTouch: false,
        touchMultiplier: 2,
        infinite: false,
      })
      lenis.scrollTo(0, { immediate: true })

      function raf(time) {
        lenis.raf(time)
        requestAnimationFrame(raf)
      }

      requestAnimationFrame(raf)
    }

    startLenis()

    return () => {
      lenis.scrollTo(0, { immediate: true })
      lenis.destroy()
    }
  }, [asPath, locale])


  return (
    <GeneralProvider options={pageProps.options} { ...pageProps.additionalData }>
      <GTMProvider>
        <MouseProvider>
          <MenuProvider menus={pageProps.menus}>

            <Metadata {...pageProps.metadata} />
            <Header />
            <DynamicIndex />
            <DynamicCursor />
            <Component key={asPath} {...pageProps} />
            <Footer />
            <FooterScripts />

          </MenuProvider>
        </MouseProvider>
      </GTMProvider>
    </GeneralProvider>
  )
}

export default MyApp
