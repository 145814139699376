import {
  rem,
  styled
} from 'config/stitches.config'

import Image from '@/Image'

Image.toString = () => '.ListImage'

export const StyledListWithImages = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexDirection: 'column-reverse',
  columnGap: rem(115),
  rowGap: rem(76),

  '@md': { flexDirection: 'row' },

  [`${Image}`]: {
    flexShrink: 0,
    flexBasis: '100%',
    '@md': {
      flexBasis: '40%',
      position: 'sticky',
      top: 128,
    },
  }
})


export const StyledListContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  rowGap: rem(48),
  '@md': { rowGap: rem(64) }
})
