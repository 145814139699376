import {
  styled, rem
} from 'config/stitches.config'

import SimpleLink from '@/UI/Link'

export const StyledNewsPreview = styled('article', {})

export const StyledNewsPreviewContainer = styled(SimpleLink, {
  textDecoration: 'none',
  width: '100%',
  flexDirection: 'column',
  rowGap: '$2',

  paddingY: '$2',

  '@md': {
    paddingY: rem(32),
    flexDirection: 'row',
    rowGap: rem(48),
  },

  '.NewsPreview_image': {
    width: '100%',
    aspectRatio: '343 / 240',

    'img': {
      size: '100%',
      objectFit: 'cover',
      objectPosition: 'center center'
    },

    '@md': {
      aspectRatio: '432 / 140',
      flexBasis: '50%',
    }
  }
})

export const StyledNewsPreviewContent = styled('div', {
  textAlign: 'left',
  width: '100%',
  'span': { marginBottom: '$1' },
  'strong p': { margin: 0 },
  '@md': {
    flexBasis: '50%',
    flexShrink: 0,
    paddingRight: rem(80)
  }
})

