import {
  styled,
  rem
} from 'config/stitches.config'

import SimpleLink from '@/UI/Link'


export const StyledMenuItemLink = styled(SimpleLink, {
  transition: 'color ease-in-out .4s',
  variants: {
    highlight: { true: { color: '$orange' } },
    current: {
      true: { color: '$darkGray' },
      false: { color: '$white', }
    }
  },
  compoundVariants: [
    {
      current: false,
      css: {
        '@md': {
          '&:hover': {
            '&::before': { transform: 'translateX(0%)' },
            'span': { transform: 'translateX(72px)' }
          }
        }
      }
    }
  ]
})

export const StyledMenuList = styled('ul', {
  margin: 0,
  padding: 0,
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$2',

  'li': { display: 'inline-block' }
})


export const StyledMenuHeader = styled('nav', {
  marginBottom: rem(64),
  overflowY: 'auto',
  overscrollBehavior: 'contain',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
  scrollBehavior: 'smooth',

  [`${StyledMenuItemLink}`]: {
    overflow: 'hidden',
    position: 'relative',
    textDecoration: 'none',
    fontSize: '$h2-mobile',
    lineHeight: '$h2-mobile',
    fontWeight: '$1',

    'span': { transition: 'transform 0.4s cubic-bezier(0.84, 0.03, 0.21, 0.99) 0s', },

    '&::before': {
      content: '',
      position: 'absolute',
      left: 0,
      top: '50%',
      width: rem(48),
      height: rem(4),
      backgroundColor: '$orange',
      transition: 'transform 0.4s cubic-bezier(0.84, 0.03, 0.21, 0.99) 0s',
      transform: 'translateX(-100%)'
    },
    '@md': {
      fontSize: '$h3',
      lineHeight: '$h3',
    },
  },
})
