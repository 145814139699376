import React from 'react'
import dynamic from 'next/dynamic'

import Image from '@/Image'
// import Video from '@/UI/Video'

import { StyledMediaWithTagImage } from './styles'

const Video = dynamic(() => import('@/UI/Video'), { ssr: false, })
const MediaWithTag = ({
  title,
  keyword,
  media,
}) => {
  const isVideo = media && media.data && media.data.attributes.mime.includes('video')

  const mediaProps = isVideo ? {
    type: 'video',
    title,
    sources: [{
      src: media.data.attributes.url,
      type: media.data.attributes.mime,
      size: 1280,
    }]
  } : {
    as: 'div',
    media,
    tags: [keyword],
  }

  return (
    isVideo
      ? <Video source={mediaProps} />
      : (
        <StyledMediaWithTagImage>
          <Image {...mediaProps} />
        </StyledMediaWithTagImage>
      )
  )
}


export default MediaWithTag
