export const GTM = { userProject: 'GTM T-Data Website' }

export const ENDPOINTS = {
  pages: '/pages',
  scenarios: '/scenarios',
  services: '/services',
  forms: '/forms',
  footer: '/footer',
  options: '/option',
  navigation: '/navigation',
  navigationSingle: '/navigation/render',
  search: '/search',
  redirects: '/redirects/all',
  news: '/news-items',
}
