import React from 'react'

import Input from '@/UI/Fields/Input'
import Address from '@/UI/Fields/Address'
import Textarea from '@/UI/Fields/Textarea'
import Select from '@/UI/Fields/Select'
import RadioGroup from '@/UI/Fields/RadioGroup'
import Search from '@/UI/Fields/Search'
import Recaptcha from '@/UI/Fields/Recaptcha'
import File from '@/UI/Fields/File'

const Controller = (props) => {
  const { type } = props
  let ControllerField = null
  switch (type) {
    case 'text':
    case 'email':
    case 'checkbox':
    case 'date':

      ControllerField = <Input {...props} />
      break
    case 'search':
      ControllerField = <Search {...props} />
      break
    case 'address':
      ControllerField = <Address {...props} />
      break
    case 'textarea':
      ControllerField = <Textarea {...props} />
      break
    case 'select':
      ControllerField = <Select {...props} />
      break
    case 'radioGroup':
      ControllerField = <RadioGroup {...props} />
      break
    case 'recaptcha':
      ControllerField = <Recaptcha {...props} />
      break
    case 'file':
      ControllerField = <File {...props} />
      break
    default:
      ControllerField =  null
  }

  return (
    ControllerField
  )
}

export default Controller
