import {
  styled,
  rem
} from 'config/stitches.config'

import Button from '@/UI/Button'

export const StyledHamburgerButton = styled(Button, {
  gridArea: 'menu',
  position: 'relative',
  height: rem(48),
  width: rem(60),
  pointerEvents: 'auto',
  backdropFilter: 'blur(10px)',

  '@md': { width: rem(80), },

  '&::before, &::after': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    content: '',
    width: '$3',
    height: '1px',
    transition: 'transform ease-in-out .6s',
    transformOrigin: 'center center'
  },


  variants: {
    size: { small: { padding: 0 } },
    theme: {
      inverted: {
        backgroundColor: 'transparent',
        border: 'none',
        '@md': { border: '1px solid $black', },
        '&::before, &::after': { backgroundColor: '$black', },
        // '@md': { '&:hover': { '&::before, &::after': { backgroundColor: '$white', }, } }
      }
    },
    isOpen: {
      false: {
        '&::before, &::after': { backgroundColor: '$black', },
        '&::before': { transform: 'translate(-50%, calc(-50% + 4px)) rotate(0)' },
        '&::after': { transform: 'translate(-50%, calc(-50% - 4px)) rotate(0)' },
      },
      true: {
        backgroundColor: 'transparent',
        '&::before, &::after': { backgroundColor: '$white', },
        '&::before': { transform: 'translate(-50%) rotate(45deg)' },
        '&::after': { transform: 'translate(-50%) rotate(-45deg)' },
      },
    },
    darkMode: { true: { '&::before, &::after': { backgroundColor: '$white', }, } },
    triggeredTheme: {
      'inverted': {
        '@md': { border: '1px solid $white', },
        '&::before, &::after': { backgroundColor: '$white', },
      }
    }
  },
  compoundVariants: [
    {
      theme: 'inverted',
      isOpen: true,
      css: {
        border: 'none', '@md': { border: '1px solid $white' }
      }
    },
    {
      theme: 'inverted',
      darkMode: true,
      css: {
        border: 'none', '@md': { border: '1px solid $white' }
      }
    }
  ],
  defaultVariants: { isOpen: 'false' }

})


export const StyledSearchButton = styled('button', {
  gridArea: 'search',
  display: 'table',
  justifySelf: 'end',
  padding: '$1',
  margin: 0,
  backgroundColor: 'transparent',
  pointerEvents: 'auto',
  color: '$black',
  border: 'none',
  cursor: 'pointer',
  variants: {
    darkMode: {
      true: { 'svg path': { fill: '$white' } },
      false: { 'svg path': { fill: 'inherit' } }
    }
  }
})


export const StyledTimer = styled('button', {
  size: 56,
  position: 'relative',
  padding: 0,
  margin: 0,
  backgroundColor: 'transparent',
  border: 'none',

  '&::before, &::after': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    content: '',
    width: '50%',
    height: 1,
    backgroundColor: '$white'
  },
  '&::before': { transform: 'translate(-50%, -50%) rotate(45deg)', },
  '&::after': { transform: 'translate(-50%, -50%) rotate(-45deg)', },

  'svg': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
})

export const StyledDropDownButton = styled(Button, {
  backgroundColor: 'transparent',
  color: '$black',
  border: 'none !important',
  columnGap: '$1',
  paddingY: '2px !important',

  '&::before': {
    flexShrink: 0,
    content: '',
    size: 12,
    br: '$round',
    outline: '1px solid $gray',
    border: '2px solid $white',

    '@md': { size: 10 },
  },

  '@md': {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    columnGap: '$2',
  },

  variants: {
    selected: {
      true: { '&::before': { backgroundColor: '$black' } },
      false: { '&::before': { backgroundColor: '$white' } },
    }
  }
})

export const StyledActiveJobButton = styled(Button, {
  columnGap: '$2',
  backgroundColor: '$black',
  color: '$white',
  border: 'none',
  '@md': { '&:hover': { backgroundColor: '$orange !important' }, },
  '&::before': {
    size: 18,
    content: '✓',
    backgroundColor: '$white',
    color: '$black'
  }
})
