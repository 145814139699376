import React from 'react'
import {
  StyledField,
  StyledFieldContainer,
  StyledFieldLabel
} from './styles'

import FieldError from './Error'

const Field = ({
  id = null,
  type,
  label,
  field,
  meta,
  children
}) => (
  <StyledField
    type={type}
    disabled={field.disabled}
  >
    <StyledFieldContainer
      type={type}
      error={meta.touched && !!meta.error}
      withValue={!!field.value.length}>

      {children}

      {
        label &&
        <StyledFieldLabel htmlFor={id || field.name} dangerouslySetInnerHTML={{ __html: label }} />
      }
    </StyledFieldContainer>

    <FieldError {...meta} />
  </StyledField>
)

export default Field
