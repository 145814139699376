import {
  styled, rem
} from 'config/stitches.config'


export const StyledTagList = styled('div', {
  display: 'inline-flex',
  gap: '$1',
  width: '100%',
  overflowX: 'scroll',
  '&::-webkit-scrollbar': { display: 'none' }
})

export const StyledTag = styled('span', {
  br: '80px',
  paddingY: rem(6),
  paddingX: rem(16),
  fontWeight: '$1',
  fontSize: '$p2-mid-mobile',
  lineHeight: '$p2-mid-mobile',
  '@md': {
    fontSize: '$p3-mid',
    lineHeight: '$p3-mid',
    paddingX: '$3',
  },
  variants: {
    theme: {
      default: {
        color: '$black',
        backgroundColor: '$lightGray',
      },
      inverted: {
        color: '$black',
        backgroundColor: '$tag',
      }
    }
  },
  defaultVariants: { theme: 'default' }
})
