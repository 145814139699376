import {
  styled,
  rem
} from 'config/stitches.config'

import { StyledSearchButton } from '@/Buttons/styles'
import { StyledLogo } from '@/Logo/styles'


export const StyledHeader = styled('header', {
  paddingTop: rem(40),
  paddingBottom: rem(30),
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 11,
  pointerEvents: 'none',
  '@md': { paddingY: rem(40), },
  variants: {
    isOpen: { true: { [`& ${StyledSearchButton} svg, & ${StyledLogo} svg g path`]: { fill: '$white' }, } },
    theme: { inverted: { [`& ${StyledSearchButton} svg, & ${StyledLogo} svg g path`]: { fill: '$white' }, } }
  },

})

export const StyledHeaderContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr  auto auto',
  gridTemplateRows: 'auto',
  gridTemplateAreas: '"logo search menu"',
  columnGap: rem(20),
  '@md': {
    columnGap: 0,
    gridTemplateColumns: '216px auto 1fr',
    gridTemplateAreas: '"logo menu search"'
  }
})
