import React, {
  useContext,
  forwardRef
} from 'react'

import withCustomCursor from 'hoc/withCustomCursor'
import { GeneralContext } from 'contexts/General'

import { StyledHamburgerButton, } from './styles'

const HamburgerMenu = (props, forwardedRef) => {
  const { page } = useContext(GeneralContext)
  const onClick = () => {
    props.menuChangeHandler()
  }

  return (
    <StyledHamburgerButton
      aria-label="Menu"
      className='no-hover'
      ref={forwardedRef}
      isOpen={props.menuOpen}
      triggeredTheme={props.triggeredTheme}
      theme="inverted"
      darkMode={page.pageDarkTheme}
      onClick={onClick}
      {...props}
    />
  )
}

export default withCustomCursor(forwardRef(HamburgerMenu))
