import React from 'react'
import { useField  } from 'formik'

import CustomField from './index'

const Input = ({
  label,
  ...props
}) => {
  const [field, meta] = useField(props)
  return (
    <CustomField
      id={props.id}
      type={props.type}
      label={label}
      field={field}
      meta={meta}
    >
      <input id={field.name} {...field} {...props} />
    </CustomField>
  )
}

export default Input
