import {
  styled,
  rem,
} from 'config/stitches.config'

import Heading from '@/UI/Heading'
import Cta from '@/UI/Cta/DefaultCta'

Heading.toString = () => '.Heading'
Cta.toString = () => '.HeaderCta'

export const StyledSectionHeader = styled('header', {
  display: 'grid',
  rowGap: '$4',
  columnGap: '$4',
  gridTemplateColumns: 'auto',
  gridTemplateRows: 'auto auto auto ',
  gridTemplateAreas: `
    "heading"
    "content"
    "cta"
  `,
  '@lg': {
    rowGap: rem(72),
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: 'auto auto',
    gridTemplateAreas: `
      "heading cta"
      "content content"
    `,
    minHeight: 0,
    minWidth: 0,
  },
  [` ${Heading}`]: {
    gridArea: 'heading',
    width: 'auto'
  },
  ' .HeaderCta': {
    gridArea: 'cta',
    width: '100%',
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
    '@md': {
      width: 'auto',
      minWidth: 0,
      minHeight: 0,
      marginBottom: rem(20)
    }
  },
})

export const SectionHeaderLower = styled('div', { gridArea: 'content', })
