import React from 'react'
import { motion } from 'framer-motion'
import ContentDivider from '@/ContentDivider'

import Heading from '@/UI/Heading'
import Container from '@/UI/Container'
import RichText from '@/UI/RichText'
import Cta from '@/UI/Cta/DefaultCta'

import { FadeIn } from 'animations'


import {
  StyledSectionHeader,
  SectionHeaderLower
} from './styles'

const SectionHeader = ({
  tag = 'h3',
  title,
  subtitle,
  description,
  descriptionStyle,
  cta,
  theme
}) => (
  <StyledSectionHeader
    as={motion.header}
    initial="hidden"
    whileInView="show"
    variants={FadeIn({
      duration: 0.6,
      delayChildren: 0.4,
      staggerChildren: 0.2
    })}
    viewport={{ once: true }}
  >

    {
      title &&
      <Heading
        as={tag}
        size={1}
        dangerouslySetInnerHTML={{ __html: title }}
        css={{
          '.simplecontent &': {
            wordBreak: 'break-word',
            '@md': { wordBreak: 'unset' }
          }
        }}
      />
    }

    {
      cta &&
      <Cta
        className="HeaderCta"
        withIcon
        href={cta.href}
        target={cta.target}
        size="big"
        icon="arrowRight"
        theme={theme}>
        {cta.label}
      </Cta>
    }

    {
      (subtitle || description) &&
      <SectionHeaderLower>
        {
          subtitle &&
          <ContentDivider title={subtitle} theme={theme === 'default' ? 'lightGray' : theme} size="sub-desk" />
        }

        {
          description &&
          <Container
            layout="full"
            size={{ '@md': descriptionStyle === 'multiple' ? 12 : 7 }}
            css={{
              marginTop: '$3',
              '@media(max-width: 640px)': { 'p': { columnCount: '1 !important' } },
              '@md': { marginTop: '$4' }
            }}>
            <RichText as='div' content={description} />
          </Container>
        }

      </SectionHeaderLower>
    }


  </StyledSectionHeader>
)

export default SectionHeader
