import React from 'react'

import SectionContainer from '@/Section/Container'
import { normalizedContentData } from 'services/models/NewsItemsArchive'

import Filters from '@/Filters'
import NewsPreview from '@/Previews/NewsPreview'

const NewsItemsArchive = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) => (
  <SectionContainer
    theme={theme}
    sectionHeader={sectionHeader}
    headerCta
  >
    <Filters
      {...sectionContent}
      ElementToRender={NewsPreview}
      normalizeFunction={normalizedContentData}
      overlap={false}
    />
  </SectionContainer>
)

export default NewsItemsArchive
