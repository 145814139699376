import {
  styled,
  rem
} from 'config/stitches.config'

import SimpleLink from '@/UI/Link'

SimpleLink.toString = () => 'ServicePreviewLink'


export const StyledServices = styled('div', {
  display: ' flex',
  alignItems: ' flex-end',
  flexWrap: ' wrap',
  columnGap: '$4',
  rowGap: '$2',
  marginTop: rem(64),
  '@md': {
    rowGap: '$4',
    marginTop: rem(100)
  }
})

export const StyledServicePreview = styled('div', {
  width: '100%',
  minWidth: 0,
  '.icon': { justifyContent: 'flex-start' }
})

export const StyledServicePreviewContainer = styled(SimpleLink, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: rem(26),
  transition: 'background-color ease-in-out .4s, color ease-in-out .4s',
  textDecoration: 'none',
  backgroundColor: '$pictureBackground',
  br: 20,

  '@md': {
    minHeight: 360,
    '&:hover': {
      backgroundColor: '$black',
      color: '$white',

      'svg, object, object embed svg': { fill: '$white' },
      'p': { color: '$lightGray' },

    },
  },

  'svg': { transition: 'fill ease-in-out .4s', },
})
export const StyledServicePreviewContent = styled('div', {
  marginTop: rem(50),
  '@md': { marginTop: 'auto', },
  'strong': {
    fontWeight: '$1',
    marginBottom: '$2',
    '@md': { marginBottom: '$4', }
  },

  'p': {
    transition: 'color ease-in-out .4s',
    color: '$gray'
  }
})
