import React, { useState } from 'react'

import Icon from '@/UI/Icon'
import Heading from '@/UI/Heading'
import Button from '@/UI/Button'
import NewsCarousel from '@/Previews/NewsCarousel'
import { StyledHeroSubtitle } from './styles'

const HeroSubtitle = ({
  content,
  withBackground = false,
  showNews = false,
  news,
}) => {
  const displayNews = showNews && news && !!news.length
  const [hovered, setHovered] = useState(false)

  return (
    <StyledHeroSubtitle
      withBackground={withBackground}
      showNews={showNews}
      hovered={hovered}
    >
      <Button
        as="div"
        className='no-hover'
        size="verySmall"
        css={{
          flexShrink: 0,
          justifyContent: 'center'
        }}
      >
        <Icon css={{ size: '80%' }} theme="inverted" name="arrowRight" />
      </Button>
      {
        displayNews &&
        <NewsCarousel items={news} onHovered={setHovered}/>
      }

      {
        content &&
        !displayNews &&
        <Heading as="h2" size="s1">{content}</Heading>
      }
    </StyledHeroSubtitle>
  )
}

export default HeroSubtitle
