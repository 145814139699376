import {
  styled,
  rem
} from 'config/stitches.config'

import breakpoints from 'theme/breakpoints'

export const StyledSectionContainer = styled('div', {
  position: 'relative',
  zIndex: 1,
  width: '100%',
  boxSizing: 'border-box',

  variants: {
    theme: {
      inverted: {
        backgroundColor: '$black',
        br: 30,
        color: '$lightGray',
        '.Heading': { color: '$white' }
      }
    }
  },
  defaultVariants: { theme: 'default' }
})

export const StyledSectionContent = styled('div', {
  display: 'block',
  margin: '0 auto',
  width: '100%',
  '@xl': { maxWidth: breakpoints.xl, },

  paddingY: rem(100),
  variants: {
    theme: {
      default: {
        paddingX: '$2',
        '@md': {
          paddingLeft: rem(288),
          paddingRight: rem(112)
        },
        '@lg': { paddingRight: rem(216) },
      },
      inverted: {
        paddingX: '$3',
        '@md': {
          paddingY: rem(180),
          paddingLeft: rem(264),
          paddingRight: rem(88)
        },
        '@lg': { paddingRight: rem(192) },
      }
    },
    noPadding: { true: { padding: 0 } }
  },
  defaultVariants: { theme: 'default' }
})
