import {
  styled,
  rem
} from 'config/stitches.config'

import Button from '@/UI/Button'

export const StyledCta = styled(Button, {
  flexShrink: 0,
  '.Cta-icon': {

    br: '$round',
    transition: 'background-color ease-in-out .4s',
    '> svg': {
      fill: '$orange',
      height: '$2',
      width: '$2',
    },
  },
  variants: {
    withIcon: {
      true: {
        paddingRight: '$1',
        paddingY: '$1',
      }
    },
    size: {
      small: {
        paddingRight: '$1',
        '.Cta-icon': {
          height: '$3',
          width: '$3',
        }
      },
      big: {
        height: rem(48),
        fontWeight: '$1',
        'button&': { paddingRight: '$1' },
        '.Cta-icon': {
          height: '$4',
          width: '$4',
        }
      },
    },
    theme: {
      default: { '.Cta-icon': { backgroundColor: '$white', }, },
      inverted: { '.Cta-icon': { backgroundColor: '$black' }, },
    },
    disabled: {
      true: { opacity: 0.5 },
      false: { opacity: 1 }
    },
    darkMode: {
      true: {
        backgroundColor: '$white',
        color: '$black',
        '.Cta-icon': { backgroundColor: '$black' },
      }
    }
  },
  defaultVariants: { withIcon: false }
})


export const StyledSeparatedIconCta = styled('div', {
  display: 'inline-flex',
  alignItems: 'stretch',
  position: 'relative',
  height: rem(30),
  textDecoration: 'none',
  columnGap: '$1',
  '@md': {
    height: rem(40),
    columnGap: '$2',
  },
  '.SeparatedIconCta-label': {
    textAlign: 'center',
    zIndex: 2,
    fontSize: '$p3-mid-mobile',
    lineHeight: '$p3-mid-mobile',
    '@md': {
      fontSize: '$p3-mid',
      lineHeight: '$p3-mid',
    }
  }
})

export const StyledSeparatedIconCtaSlot = styled('div', {
  $$size: '30px',
  $$fullGap: '56px',
  size: '$$size',
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  br: '$round',
  zIndex: 1,


  ' svg': {
    size: '50%',
    '@md': { size: '70%' }
  },
  '@md': {
    $$size: '40px',
    size: '$$size',
    opacity: 0,
    transform: 'translateX(-$$fullGap)',
    transition: 'transform ease-in-out .6s, opacity ease-in-out .6s',
  },
  variants: {
    theme: {
      default: {
        backgroundColor: '$black',
        'svg': { fill: '$white' }
      },
      inverted: {
        backgroundColor: '$white',
        'svg': { fill: '$black' }
      }
    }
  },
  defaultVariants: { theme: 'default' }
})
