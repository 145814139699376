import {
  styled,
  rem
} from 'config/stitches.config'


export const StyledInputFileLabel = styled('span', {
  textIndent: rem(4),
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  fontSize: '$subtitle-2-mobile',
  lineHeight: '$subtitle-2-mobile',
  width: '100%',
  height: rem(48),
  borderBottom: '2px solid',
  pointerEvents: 'none',
  '@md': {
    fontSize: '$subtitle-2',
    lineHeight: '$subtitle-2',
  },
})

export const StyledField = styled('div', {
  position: 'relative',
  marginBottom: '$4',

  '&:last-child': { marginBottom: 0 },

  '&[type="checkbox"], &[type="radio"]': {
    width: 'auto',

    '> div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  variants: {
    type: {
      default: {
        'label': {
          pointerEvents: 'none',
          width: '100%',
        }
      },
      radio: {
        minWidth: '150px',
        'label': {
          pointerEvents: 'auto',
          width: 'auto',
        }
      },
      checkbox: {
        'label': {
          pointerEvents: 'auto',
          width: 'auto',
        }
      },
    }
  },
  defaultVariants: { type: 'default' }
})

export const StyledFieldContainer = styled('div', {
  position: 'relative',
  width: '100%',

  'input[type="search"]': {

    '&:focus-visible': {
      outline: 'none',
      border: '1px solid $orange !important',

      borderRadius: '30px',
    }
  },

  'input:not([type="checkbox"]):not([type="radio"]), select, textarea': {
    textIndent: rem(4),
    width: '100%',
    height: rem(48),
    border: 'none',
    backgroundColor: 'transparent',
    color: 'inherit',
    fontSize: '$subtitle-2-mobile',
    lineHeight: '$subtitle-2-mobile',
    '@md': {
      fontSize: '$subtitle-2',
      lineHeight: '$subtitle-2',
    },
  },

  'input:not([type="checkbox"]):not([type="radio"]), textarea': {
    padding: 0,
    borderBottom: '2px solid',
    fontFamily: '$base',

    '&::placeholder': {
      all: 'unset',
      color: 'inherit',
      fontFamily: '$base'
    },

    '&:focus, &:not(:placeholder-shown)': { borderBottom: '2px solid', },

    '&:placeholder-shown + label': {
      color: 'transparent',
      userSelect: 'none',
    }
  },

  'input:focus:not([type="checkbox"]):not([type="radio"]) + label, input:not([type="checkbox"]):not([type="radio"]):not(:placeholder-shown) + label, textarea:focus + label, textarea:not(:placeholder-shown) + label': {
    transform: 'translate(0, -90%) scale(0.45)',
    color: 'inherit',
  },

  'textarea': {
    width: '100%',
    display: 'block',
    minHeight: rem(180)
  },

  'textarea + label': { top: '16px' },
  'input[type="checkbox"], input[type="radio"]': {
    size: '20px',
    padding: 0,
    margin: 0,
    opacity: 0,
    visibility: 'hidden',
  },

  'input[type="checkbox"] + label, input[type="radio"] + label': {
    display: 'block',
    paddingTop: rem(3),
    position: 'relative',
    top: 'unset',
    transform: 'none',
    cursor: 'pointer',
    fontSize: '$sub-desk',
    lineHeight: '$sub-desk',
    paddingLeft: '$2',

    '&:before': {
      content: '',
      size: '$2',
      transition: 'background-color ease-in-out .3s, border-color ease-in-out .3s, border-width ease-in-out .2s',
      border: '2px solid',
      position: 'absolute',
      right: '100%',
      top: 0,
    },

    '&:hover:before': { borderColor: '$black', }
  },

  'input[type="radio"] + label': { '&:before': { br: '50%', } },

  'input[type="checkbox"]:checked + label, input[type="radio"]:checked + label': {
    '&:before': {
      borderColor: 'inherit',
      backgroundColor: 'inherit'
    }
  },

  'input[type="checkbox"]:checked + label': {
    '&:before': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      content: '✓',
      color: '$white',
      backgroundColor: '$black'
    },
  },

  'input[type="file"]': {
    opacity: 0,
    '&:not(:placeholder-shown) + label': {
      all: 'unset',
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%) scale(1) !important',
      color: '$gray',
      fontSize: '$p3-mid',
      display: 'inline-block'
    },
    '&::-webkit-file-upload-button': { display: 'none' }
  },

  'select': {
    textIndent: 0,
    fontWeight: '$2',
    padding: 0,
    margin: 0,
    fontFamily: '$base',
    'option': {
      color: '$black',
      fontWeight: '$2',
      padding: 0
    }
  },

  variants: {
    type: {
      checkbox: {
        display: 'flex',
        alignItems: 'flex-start',
      },
      radio: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    error: {
      true: {
        'input:not([type="checkbox"]):not([type="radio"]), textarea,': {
          '&::placeholder': { color: '$error', },
          borderColor: '$error',
          '&:focus, &:not(:placeholder-shown)': { borderColor: '$error', },
        },
        'select': { borderBottom: '2px solid $error', },
        'input[type="checkbox"] + label, input[type="radio"] + label': {
          color: '$error',
          '&:before': { borderColor: '$error', }
        },
        'input[type="file"] + span': {
          borderColor: '$error',
          color: '$error'
        }
      },
      false: {
        'input:not([type="checkbox"]):not([type="radio"]), textarea': {
          borderColor: '$gray',
          '&:focus, &:not(:placeholder-shown)': { borderColor: '$gray', },
        },
        'select': { borderBottom: '2px solid $gray', },
        'input[type="checkbox"] + label, input[type="radio"] + label': {
          color: 'inherit',
          a: { color: 'inherit', },
          '&:before': { borderColor: '$gray', }
        }
      }
    }
  },
  defaultVariants: { error: 'false' }
})

export const StyledFieldLabel = styled('label', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  position: 'absolute',
  top: '50%',
  left: 0,
  color: '$black',

  transformOrigin: 'top left',
  transform: 'translate(2%,-50%)',
  transition: 'transform .3s ease-in-out, color .3s ease-in-out',
  fontSize: '$subtitle-2-mobile',
  lineHeight: '$subtitle-2-mobile',

  '@md': {
    fontSize: '$subtitle-2',
    lineHeight: '$subtitle-2',
  }
})

export const StyledFieldError = styled('div', {
  position: 'absolute',
  left: 0,
  top: '100%',
  color: '$error',
  fontSize: '$p3-mid-mobile',
  lineHeight: '$p3-mid-mobile',
  marginTop: '$1',

  '@md': {
    fontSize: '$p3-mid',
    lineHeight: '$p3-mid',
  }
})

export const StyledRadioGroup = styled('div', {
  display: 'block',
  marginBottom: '$3',

  'strong': {
    display: 'block',
    marginBottom: '$2',
  }
})

export const StyledRadioGroupContainer = styled('div', {
  display: 'inline-flex',
  width: '100%',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  columnGap: '$2',
  rowGap: '$2',
})


