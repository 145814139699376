import {
  styled, rem
} from 'config/stitches.config'

const Grid = styled('div', {
  display: 'grid',

  variants: {
    align: {
      start: { alignItems: 'start', },
      center: { alignItems: 'center', },
      end: { alignItems: 'end', },
      stretch: { alignItems: 'stretch', },
      baseline: { alignItems: 'baseline', },
    },
    justify: {
      start: { justifyContent: 'start', },
      center: { justifyContent: 'center', },
      end: { justifyContent: 'end', },
      between: { justifyContent: 'space-between', },
    },
    flow: {
      row: { gridAutoFlow: 'row', },
      column: { gridAutoFlow: 'column', },
      dense: { gridAutoFlow: 'dense', },
      rowDense: { gridAutoFlow: 'row dense', },
      columnDense: { gridAutoFlow: 'column dense', },
    },
    columns: {
      1: { gridTemplateColumns: 'repeat(1, 1fr)', },
      2: { gridTemplateColumns: 'repeat(2, 1fr)', },
      3: { gridTemplateColumns: 'repeat(3, 1fr)', },
      4: { gridTemplateColumns: 'repeat(4, 1fr)', },
      5: { gridTemplateColumns: 'repeat(5, 1fr)', },
      6: { gridTemplateColumns: 'repeat(6, 1fr)', },
    },
    gapX: {
      1: { columnGap: rem(120), },
      2: { columnGap: '$2', },
      3: { columnGap: '$3', },
      4: { columnGap: '$4', },
    },
    gapY: {
      1: { rowGap: rem(76), },
      2: { rowGap: rem(40), },
      3: { rowGap: '$3', },
      4: { rowGap: '$4', }
    },
  },
})

export default Grid
