import {
  styled, rem
} from 'config/stitches.config'

export const StyledParagraph = styled('div', {

  '.Divider': {
    marginBottom: rem(24),
    '@md': { marginBottom: rem(32) }
  },

  'p': { margin: 0 },

  '.ParagraphImage': {
    'img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transform: 'scale(1,1)',
      transformOrigin: 'center center',
      transition: 'transform ease-in-out .4s',
    },
    // '&:hover img': { transform: 'scale(1.05,1.05)', },
  },

  variants: {
    full: {
      true: { flexBasis: '100%' },
      false: { '@lg': { flexBasis: 'calc(50% - 60px)' } },
    }
  }
})

export const StyledParagraphContent = styled('div', { overflow: 'hidden' })
