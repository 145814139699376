import React from 'react'
import { AnimatePresence } from 'framer-motion'

import {
  StyledNewsCarouselListItem,
  StyledNewsCarouselListContent
} from './styles'

const animationOpts = {
  initial: {
    opacity: 0,
    transform: 'translateY(150%)',
    transition: { duration: 0.6 }
  },
  animate: {
    opacity: 1,
    transform: 'translateY(-50%)',
    transition: { duration: 1 }
  },
  exit: {
    opacity: 0,
    transform: 'translateY(-150%)',
    transition: { duration: 1 }
  },
}

const NewsCarouselList = ({
  current,
  items
}) => {
  return (
    <AnimatePresence>
      {
        items &&
        items.map((item, idx) =>(
          idx === current &&
          <StyledNewsCarouselListItem
            layout
            key={item.title + item.id}
            {...animationOpts}
          >
            <StyledNewsCarouselListContent {...item.link}>
              {item.title}
            </StyledNewsCarouselListContent>
          </StyledNewsCarouselListItem>
        ))
      }
    </AnimatePresence>
  )
}

export default NewsCarouselList
