import React, {
  useState,
  useEffect
} from 'react'

import { fetchData } from 'services/API'

import Tabs from '@/UI/Tabs'
import Cta from '@/UI/Cta/DefaultCta'

import ContentList from './List'
import ActiveFilters from './ActiveFilters'

import {
  StyledFiltersCta,
  StyledFilterNoResult
} from './styles'

const Filters = ({
  filters,
  preview,
  endpoint,
  params,
  ElementToRender,
  normalizeFunction,
  loadMoreLabel,
  activeFiltersLabel,
  overlap = true,
  noResultMessage,
}) => {
  const initialButtonState = !!endpoint
  const [selectedTab, setSelectedTab] = useState({})
  const [items, setItems] = useState(filters?.content || null)
  const [currentPage, setCurrentPage] = useState(1)
  const [showButton, setShowButton] = useState(initialButtonState)

  const onTabClick = (cluster, id, title) => {
    setSelectedTab(prev => {
      const newState = { ...prev }
      const value = newState[cluster]?.id === id ? null : id

      newState[cluster] = value
        ? {
          id, title
        }
        : null

      if (!newState[cluster]) delete newState[cluster]

      const clusterSelected = filters.header.find(({ id: cId }) => cId === cluster)

      const autoSelectItems = clusterSelected.items.find(({
        relation,
        id: itemId
      }) => !!relation && itemId === value)

      if (autoSelectItems && value) {
        const {
          relation: {
            id: relationID,
            entity
          }
        } = autoSelectItems

        const findAutoSelectedCluster = filters.header.find(filter => filter.id === entity)
        const autoSelectedValue = findAutoSelectedCluster.items.find(item => item.id === relationID)
        newState[entity] = { ...autoSelectedValue }
      }

      const itemParents = clusterSelected.items.find(({
        parents,
        id: itemId
      }) => !!parents && itemId === value)

      if (itemParents && value) {
        newState[cluster].parents = itemParents.parents
      }

      const attributes = clusterSelected.items.find(({
        attrs,
        id: itemId
      }) => !!attrs && itemId === value)

      if (attributes && value) {
        newState[cluster].attrs = attributes.attrs
      }

      return newState
    })
  }

  const onLoadMore = async ({
    page,
    loadMore = false
  }) => {
    const apiURL = process.env.NEXT_PUBLIC_API_URL
    const dynamicParams = Object.keys(selectedTab).reduce((acc, key) => {
      let paramKey = `filters[${key}]`

      if (selectedTab[key].parents) {
        paramKey = `filters${selectedTab[key].parents.map(p => `[${p}]`).toString().replace(',', '')}[${key}]`
      }

      if (selectedTab[key].attrs) {
        paramKey = `${paramKey}${selectedTab[key].attrs.map(attr => `[${attr}]`).toString().replace(',', '')}`
      }

      const param = { [`${paramKey}[$eq]`]: selectedTab[key].id }
      Object.assign(acc, param)
      return acc
    }, {})

    const allParams = {
      ...params,
      'pagination[page]': page,
      ...dynamicParams
    }
    const queryString = new URLSearchParams(allParams).toString()
    const endpointURL = `${apiURL}${endpoint}?${queryString}`
    const {
      data,
      meta
    } = await fetchData(endpointURL)

    const { pagination } = meta

    if ((!data || data.length === 0) && loadMore) return null

    const itemsNormalized = normalizeFunction(data)

    let i = [...itemsNormalized]

    if (loadMore) {
      i = [...items, ...i]
    }

    return {
      data: i,
      total: pagination.total
    }
  }

  const getData = async (page, opts = {}) => {
    let buttonState = true

    const {
      data,
      total
    } = await onLoadMore({
      page,
      ...opts
    })

    if (!data || data.length === 0 || data.length === Number(total)) {
      buttonState = false
    }

    setShowButton(buttonState)
    setCurrentPage(page)
    setItems(data)
  }

  useEffect(() =>{
    const onFilterSelected = async () => {
      const page = 1
      await getData(page)
    }

    onFilterSelected()
  }, [selectedTab])

  const onButtonClick = async () => {
    const page = currentPage + 1
    await getData(page, { loadMore: true })
  }

  if (preview) return <ContentList items={filters.content} ElementToRender={ElementToRender} />

  return (
    <Tabs
      theme={'noBackground'}
      header={filters.header}
      onTabClick={onTabClick}
      selectedTab={selectedTab}
      withDropDown={true}
      initialState={false}
      overlap={overlap}
    >
      <ActiveFilters label={activeFiltersLabel} items={selectedTab} onClick={onTabClick} />
      <ContentList
        items={items}
        ElementToRender={ElementToRender}
      />

      {
        noResultMessage &&
        (!items || items.length === 0) &&
        <StyledFilterNoResult content={noResultMessage} />
      }

      {
        showButton &&
        <StyledFiltersCta>
          <Cta
            as="button"
            onClick={onButtonClick}
            className="Filter_cta"
            theme="default"
            withIcon
            size="big"
            icon="arrowRight"
          >
            {loadMoreLabel}
          </Cta>

        </StyledFiltersCta>
      }
    </Tabs>
  )
}

export default Filters
