import {
  styled, rem
} from 'config/stitches.config'

export const StyledFooter = styled('footer', {
  color: '$white',
  backgroundColor: '$black',
  paddingTop: rem(122),
  paddingBottom: rem(40),
  '@md': {
    paddingTop: rem(234),
    paddingBottom: 0,
  }
})

export const StyledFooterRow = styled('section', {
  display: 'block',
  width: '100%',
  paddingY: 0,
  '@md': {
    borderTop: '1px solid $white',
    paddingTop: rem(32),
    paddingBottom: rem(64),
  }
})

export const StyledFooterCol = styled('div', {})

export const StyledFooterLower = styled('div', {
  width: '100%',
  display: 'grid',
  'grid-template-columns': '1fr 1fr',
  'grid-template-rows': 'auto',
  'grid-template-areas': `
    "menus menus"
    "company iva"
  `,
  '@md': {
    'grid-template-columns': '1fr 10fr 1fr',
    'grid-template-rows': 'auto',
    'grid-template-areas': `
      "company menus iva"
    `,
  }
})
