import React, {  useState } from 'react'
import { SwiperSlide } from 'swiper/react'

import Slider from '@/Sliders/Slider'
import ReviewSlide from '@/Sliders/Slides/ReviewSlide'
import Navigation from '@/Sliders/Navigation'

import Counter from './Counter'

import { StyledReviewsListInfos } from './styles'

const sliderConfig = {
  slidesPerView: 1,
  speed: 600,
}
const SliderReviewList = ({ items }) => {
  const [currentSlide, setCurrentSlide] = useState(1)
  const totalSlides = items.length
  return (
    <Slider
      className="ReviewSlider"
      config={sliderConfig}
      navigation={{
        nextEl: '#nav-next',
        prevEl: '#nav-prev',
      }}
      onSlideChange={(a) => { setCurrentSlide(a.activeIndex + 1)}}
      totalSlides={totalSlides}
    >
      {items.map((review, idx) => (
        <SwiperSlide key={idx}>
          <ReviewSlide activeIndex={currentSlide} {...review} />
        </SwiperSlide>
      ))}
      {
        items &&
        items.length > 1 &&
        <StyledReviewsListInfos >
          <Counter currentSlide={currentSlide} totalSlides={totalSlides}/>
          <Navigation activeIndex={currentSlide} />
        </StyledReviewsListInfos>
      }

    </Slider>
  )
}

export default SliderReviewList
