import { styled } from 'config/stitches.config'

import Image from 'next/image'

export const StyledImage = styled(Image, {
  verticalAlign: 'middle',
  maxWidth: '100%',
  maxHeight: '100%',
  height: 'auto',
})
