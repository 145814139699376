import {
  styled,
  rem
} from 'config/stitches.config'

import Grid from '@/UI/Grid'
import Button from '@/UI/Button'
import Heading from '@/UI/Heading'
import Text from '@/UI/Text'
import SimpleLink from '@/UI/Link'

export const StyledServicesPreview = styled(Grid, {
  columnGap: '$4',
  rowGap: '$2',
  marginTop: rem(64),
  '@md': {
    rowGap: '$4',
    marginTop: rem(100)
  }
})


export const StyledServicesRows = styled('div', {
  display: 'block',
  width: '100%',
  marginTop: rem(36)
})

export const StyledServicesRow = styled(SimpleLink, {
  display: 'block',
  width: '100%',
  paddingY: rem(34),
  borderBottom: '1px solid $black',
  textDecoration: 'none',

  '@md': {
    borderBottom: 'none',
    borderTop: '1px solid $black',
    paddingY: rem(64),
  }
})

export const StyledServicesRowHeader = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  columnGap: rem(18),

  [Heading]: { '@md': { marginTop: '$1', } },

  [Button]: {
    flexShrink: 0,
    justifyContent: 'center',
    '@md': { display: 'none', }
  },

  '@md': { columnGap: rem(30), }
})

export const StyledServicesRowIcon = styled('div', {
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  size: rem(24),


  'svg': { size: rem(24) },

  '@md': {
    size: rem(56),
    border: '1px solid $lightGray',
    br: '$round',
  },
})

export const StyledServicesRowContent = styled('div', {
  marginTop: rem(28),
  color: '$gray',

  [Text]: { '@lg': { width: '50%' } },

  '@md': {
    marginTop: '$3',
    paddingLeft: rem(86),
  }
})


export const StyledServicesImage = styled('div', {
  marginTop: rem(64),
  '@md': { marginTop: rem(100) }
})
