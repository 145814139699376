import React, {
  useEffect,
  useState
} from 'react'

import NewsCarouselList from './List'
import { StyledNewsCarouselList } from './styles'

const NewsCarousel = ({
  items,
  onHovered
}) => {
  const [current, setCurrent] = useState(0)
  const [intervalId, setIntervalId] = useState(null)

  const onListHovered = (hoverStatus) => {
    if (!onHovered || onHovered instanceof Function === false) return
    onHovered(hoverStatus)
  }

  const runCarousel = () => {
    const iId = setInterval(() => {
      if (!items || items.length === 0) return
      let nextCurrent = current + 1
      if (nextCurrent > items.length - 1) nextCurrent = 0
      setCurrent(nextCurrent)
    }, 3000)

    setIntervalId(iId)
  }

  const onMouseOver = () => {
    onListHovered(true)
    clearInterval(intervalId)
  }

  const onMouseLeave = () => {
    onListHovered(false)
    runCarousel()
  }

  useEffect(() => {
    runCarousel()

    return () => clearInterval(intervalId)
  }, [current])


  return (
    <StyledNewsCarouselList
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <NewsCarouselList
        items={items}
        current={current}/>
    </StyledNewsCarouselList>
  )
}


export default NewsCarousel
