import React from 'react'

import Text from '@/UI/Text'
import SimpleLink from '@/UI/Link'

import {
  StyledTriggerButton,
  StyledMenuFooterHeader
} from './styles'

const MenuFooterHeader = ({
  isOpen,
  label,
  mainExists = true,
  collapsable = true,
  collapsableLabel = null,
  onButtonClick,
  ...rest
}) => {
  return (
    <StyledMenuFooterHeader>
      <Text
        as={SimpleLink}
        size={{
          '@initial': 'p1-mid-mobile',
          '@md': 'footer'
        }}
        {...rest}
      >
        {label}
      </Text>

      {
        collapsable &&
        mainExists &&
        <StyledTriggerButton
          onClick={onButtonClick}
          isOpen={isOpen}
          aria-label={collapsableLabel ? collapsableLabel : ''}
        />
      }
    </StyledMenuFooterHeader>
  )
}

export default MenuFooterHeader
