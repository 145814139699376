import React, { useState } from 'react'

import Tabs from '@/UI/Tabs'
import JobsList from './List'

const Jobs = ({
  jobs,
  preview
}) => {
  const [selectedTab, setSelectedTab] = useState()

  if (preview) return <JobsList items={jobs.content} />

  const onTabClick = (id) => {
    const tab = selectedTab !== id ? id : null

    setSelectedTab(tab)
  }

  return (
    <Tabs
      theme={'noBackground'}
      header={jobs.header}
      onTabClick={onTabClick}
      selectedTab={selectedTab}

    >
      <JobsList
        selectedCategory={selectedTab}
        items={jobs.content}
      />
    </Tabs>
  )
}

export default Jobs
