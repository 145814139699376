import React from 'react'

import MenuItem from '../MenuItem'

import {
  StyledMenuFooterMain,
  StyledMenuFooterMainUl
} from './styles'

const MenuFooterMain = ({
  items,
  direction,
  cluster,
}) => (
  items &&
  !!items.length &&
  <StyledMenuFooterMain>
    <StyledMenuFooterMainUl direction={direction}>
      {
        items.map(({
          id,
          label,
          ...rest
        }, idx) => <li key={idx}><MenuItem id={`item_${id}_${cluster.toLowerCase().replace(' ', '-')}`} {...rest} title={label} /></li>)
      }
    </StyledMenuFooterMainUl>
  </StyledMenuFooterMain>
)

export default MenuFooterMain
