import React, {
  useRef,
  useEffect,
  useCallback
} from 'react'
import { gsap } from 'gsap'
import SplitText from 'gsap/dist/SplitText'

import SectionContainer from '@/Section/Container'

import MediaWithTag from '@/MediaWithTag'
import Heading from '@/UI/Heading'
import Divider from '@/UI/Divider'

import SubTitle from './Subtitle'

import {
  ScaleX,
  FadeIn
} from 'animations'

import {
  StyledHero,
  StyledHeroContainer,
  StyledHeroSubtitleWrapper
} from './styles'


const Hero = ({
  title,
  subtitle,
  asTitle = true,
  mediaWithTag,
  showNews,
  news = null
}) => {
  const titleToAnimate = useRef(null)
  const isFullScreen = !!mediaWithTag && mediaWithTag.layout === 'cover'

  const animationCallback = useCallback(() => {
    const titleToAnimateSplitted = new SplitText(titleToAnimate.current, {
      type: 'words,chars',
      wordsClass: 'HeroTitleWord',
    })

    const tl = gsap.timeline()

    tl.from(titleToAnimateSplitted.chars, {
      duration: 0.6,
      delay: 1,
      opacity: 0,
      y: 80,
      stagger: 0.02
    })
  })

  useEffect(animationCallback, [animationCallback])

  return (
    <StyledHero
      fullScreen={isFullScreen}
    >

      <StyledHeroContainer>
        <SectionContainer
          css={{ paddingY: 0 }}
          variants={FadeIn({ staggerChildren: 0.2 })}
        >
          {
            title &&
            <Heading

              css={{ maxWidth: 900 }}
              className={ isFullScreen ? 'TitleInCover' : ''}
              as={asTitle ? 'h1' : 'h2'}
            >
              <div
                ref={titleToAnimate}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Heading>
          }
          {
            (subtitle || showNews) &&
            <StyledHeroSubtitleWrapper>
              <Divider
                variants={ScaleX({ opts: { delay: 0.7 } })}
              />
              <SubTitle content={subtitle} withBackground={isFullScreen} showNews={showNews} news={news} />
            </StyledHeroSubtitleWrapper>
          }

          {
            mediaWithTag &&
            mediaWithTag.layout !== 'cover' &&
            <MediaWithTag title={title} {...mediaWithTag} />
          }
        </SectionContainer>
      </StyledHeroContainer>
      {
        mediaWithTag &&
        mediaWithTag.layout === 'cover' &&
        <MediaWithTag title={title} {...mediaWithTag} />
      }
    </StyledHero>
  )
}

export default Hero
