import React from 'react'

import ContentDivider from '@/ContentDivider'
import Grid from '@/UI/Grid'
import RichText from '@/UI/RichText'
import Image from '@/Image'
import ImageWithLabelRepeater from '@/ImageWithLabelRepeater'

import {
  StyledParagraph,
  StyledParagraphContent
} from './styles'

const Paragraph = ({
  title = null,
  description,
  theme = 'default',
  size,
  imagesWithTag = null,
  tinyImageWithLabel = null
}) => {
  const imageProps = {}

  if (imagesWithTag) {
    Object.assign(imageProps, {
      ...imagesWithTag,
      as: 'div',
      tags: [imagesWithTag.keyword]
    })
  }

  return (
    <StyledParagraph full={!!imagesWithTag}>
      <ContentDivider title={title} theme={theme} size={size} />

      <Grid
        gapX={{
          '@middle': 4,
          '@lg': 1,
        }}
        gapY={2}
        columns={{
          '@initial': 1,
          '@middle': imagesWithTag ? 2 : 1
        }}>

        <StyledParagraphContent>
          <RichText as='div' content={description} />
          <ImageWithLabelRepeater items={tinyImageWithLabel} />
        </StyledParagraphContent>
        {
          imagesWithTag &&
          <Image className="ParagraphImage" {...imageProps}/>
        }

      </Grid>

    </StyledParagraph>
  )
}

export default Paragraph
