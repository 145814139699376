
import React from 'react'
import { motion } from 'framer-motion'

import Image from '@/Image'
import ClientListPreview from '@/Previews/ClientListPreview'

import {
  StyledListWithImages,
  StyledListContent,
} from './styles'

const container = {
  hidden: {  },
  show: {
    transition: {
      duration: 1,
      staggerChildren: 0.3
    }
  },
}
const ListWithImages = ({
  theme,
  items,
  image = null
}) => {
  return (
    <StyledListWithImages>
      {
        image &&
        <Image
          theme={theme}
          as="div"
          className="ListImage"
          media={image.media}
          tags={[image.keyword]} />
      }

      <StyledListContent
        as={motion.div}
        variants={container}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
      >
        {items.map((item, idx) => <ClientListPreview idx={idx + 1} key={idx} {...item} />)}
      </StyledListContent>

    </StyledListWithImages>
  )
}

export default ListWithImages
