import {
  styled,
  rem
} from 'config/stitches.config'

import Link from '@/UI/Link'

export const StyledLogo = styled(Link, {
  gridArea: 'logo',
  justifySelf: 'start',
  paddingRight: '$3',
  pointerEvents: 'auto',

  'svg': {
    width: rem(80),
    '@md': { width: rem(120) }
  },
  variants: { darkMode: { true: { 'svg path': { fill: '$white' } }, } }
})
