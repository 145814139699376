import React, {
  useState,
  useEffect,
  useContext,
  createContext
} from 'react'

import { useRouter } from 'next/router'

import TagManager from 'react-gtm-module' // TODO: evaluate to change with something more specific for NextJs or makeit manually

import { GTM } from 'config'
import { GeneralContext } from './General'

const GTMContext = createContext()

const GTMProvider = ({ children }) => {
  const router = useRouter()
  const [gtmInstance, setGtmInstance] = useState(null)
  const { options = {} } = useContext(GeneralContext)
  const gtmId = options.gtm

  /**
   * Push data to a datalayer
   * @param {object} payload
   * @returns
   */
  const pushToDataLayer = (payload = {}) => {
    if (!gtmInstance) return

    // console.log('GTM - With React-GTM-Module')

    let dataLayerPayload = {
      dataLayer: {
        payload: payload.data,
        userProject: GTM.userProject,
        page: payload.page,
        event: payload.event
      }
    }

    if (payload.dataLayerName) {
      dataLayerPayload = {
        ...dataLayerPayload, dataLayerName: payload.dataLayerName
      }
    }

    try {
      gtmInstance.dataLayer(dataLayerPayload)
    } catch (error) {
      console.error('GTM error on push:', error.message)
    }
  }
  /**
   * Wait n seconds before push data to a datalayer
   * @param {object} payload
   * @param {number} delay
   * @returns
   */
  const waitAndPushToDataLayer = async (payload = {}, delay = 1000) => {
    pushToDataLayer(payload)

    await new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, delay)
    })
  }

  /**
   * Inject new datalayer
   * @param {string} dataLayerName
   */
  const newDataLayer = (dataLayerName = '') => {
    if (!dataLayerName) {
      // console.debug('GTM - Missing datalayer name')
      return
    }

    create(dataLayerName)
  }


  /**
   * Create new datalayer
   * @param {string} dataLayerName
   */
  const create = dataLayerName => {
    // console.debug('GTM - Start loading!')

    const newOptions = Object.assign({}, { gtmId })
    if (dataLayerName) {
      newOptions.dataLayerName = dataLayerName
    }

    const instance = TagManager
    instance.initialize({ gtmId })
    setGtmInstance(instance)
  }

  useEffect(create, [])

  useEffect(() => {
    window.dataLayer.push({ event: 'additional_script_trigger' })

    const handleRouteChange = () => {
      window.dataLayer.push({ event: 'additional_script_trigger' })
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const value = {
    gtmInstance, pushToDataLayer, waitAndPushToDataLayer, newDataLayer
  }

  return <GTMContext.Provider value={value}>{children}</GTMContext.Provider>
}

export default GTMProvider
export { GTMContext }
