import React from 'react'

import RichText from '@/UI/RichText'

import { StyledMenuItemLink, } from './styles'

const MenuItem = ({
  title,
  description,
  ...menuItemProps
}) => {
  return (
    description
      ? <RichText className='FooterInfo' as='div' content={description} />
      : (
        <StyledMenuItemLink {...menuItemProps}>
          <span>{title}</span>
        </StyledMenuItemLink>
      )
  )
}


export default MenuItem
