import React from 'react'

import Grid from '@/UI/Grid'
import Image from '@/Image'

const mosaicBlock = [2, 1]

import {
  StyledImageMosaic,
  StyledImageMosaicElement,
  StyledImageMosaicElementContainer
} from './styles'


const ImagesMosaic = ({
  items,
  theme
}) => {
  const nBlock = Math.round(items.length / 2)
  const mosaicSchema = new Array(nBlock).fill(mosaicBlock)
  const imgs = [...items]

  const blocks = mosaicSchema.map(b => {
    const rows = b.reduce((acc, curr) => {
      if (imgs.length < curr) return acc
      const i = imgs.splice(0, curr)
      acc.push(i)
      return acc
    }, [])
    return rows
  })

  return (
    <StyledImageMosaic>
      {
        blocks.map((block, idx) => {
          return block.map((rows, i) => (
            <Grid
              key={`${idx}_${i}`}
              columns={{
                '@initial': 1,
                '@md': rows.length
              }}
            >
              {
                rows.map(({
                  attributes,
                  id
                }) => (
                  attributes.image.media &&
                  <StyledImageMosaicElement full={ rows.length === 1} key={id}>
                    <StyledImageMosaicElementContainer  full={ rows.length === 1}>
                      <Image
                        href={attributes.href}
                        className="GridImage"
                        theme={theme}
                        {...attributes.link}
                        {...attributes.image}
                      />
                    </StyledImageMosaicElementContainer>
                  </StyledImageMosaicElement>
                ))
              }
            </Grid>
          ))
        })
      }
    </StyledImageMosaic>
  )
}

export default ImagesMosaic
