import React from 'react'

import ServicePreview from '@/Previews/ServicePreview'

import { StyledServicesPreview, } from './styles'

const ServicesPreview = ({ data: items }) => (
  items &&
  !!items.length &&
  <StyledServicesPreview
    columns={{
      '@middle': 2,
      '@lg': 3
    }}
  >
    {
      items.map(({
        id,
        attributes: {
          ctaLabel,
          link,
          icon,
          title,
          excerpt,
        }
      }) => (
        <ServicePreview
          key={id}
          link={link}
          icon={icon}
          title={title}
          excerpt={excerpt}
          cursor={{
            blendMode: 'normal',
            type: 'invertedLink',
            text: ctaLabel,
          }}
        />
      ))
    }
  </StyledServicesPreview>
)

export default ServicesPreview
