import {
  styled, rem
} from 'config/stitches.config'

import { StyledMenuItemLink } from '../styles'

export const StyledMenuFooterWrapper = styled('div', {
  paddingY: '$3',
  borderTop: '1px solid $white',


  '&:last-child': {
    borderBottom: '1px solid $white', '@md': { border: 'none' },
  },
  '&.Contacts': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  '&.LowerMenu': { border: 'none' },

  [`& ${StyledMenuItemLink}`]: {
    color: '$accessibleLightGray',
    fontSize: '$footer',
    lineHeight: '$footer',

  },

  '@md': {
    paddingY: 0,
    border: 'none',

    '&.Contacts': { display: 'block' },

    [` ${StyledMenuItemLink}:hover`]: { color: '$white' }
  },

})


export const StyledMenuFooterHeader = styled('header', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: '$2',

  '.Contacts &': { 'a': { textAlign: 'right', } },

  'a': { textDecoration: 'none' },

  '@md': {
    marginBottom: '$4',
    '.Contacts &': { 'a': { textAlign: 'left', } },
  }
})


export const StyledTriggerButton = styled('button', {
  all: 'unset',
  flexShrink: 0,
  position: 'relative',
  size: '$4',

  '&::before, &::after': {
    content: '',
    width: 1,
    height: 8,
    backgroundColor: '$white',
    position: 'absolute',
    top: '50%',
    left: '50%',
  },

  '&::before': { transform: 'translate(-50%, -50%)', },
  '&::after': { transform: 'translate(-50%, -50%) rotate(90deg)', },

  '@md': { display: 'none' },
})

export const StyledMenuFooterMain = styled('main', {
  marginTop: '$2',
  color: '$accessibleLightGray',

  '.Contacts &': { marginTop: '0', },

  '.FooterInfo': {
    lineHeight: rem(18),
    fontSize: '$footer',
    textAlign: 'left',

    'a': { color: '$accessibleLightGray', },

    '&:not(:last-child)': { marginBottom: '$3', },
  },

  '@md': { marginTop: 0, }
})


export const StyledMenuFooterMainUl = styled('ul', {
  margin: 0,
  padding: 0,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  rowGap: '$2',
  columnGap: '$2',
  '.Contacts &': {
    alignItems: 'flex-end',
    rowGap: '0',
    '@md': { alignItems: 'flex-start', }
  },

  'li': { display: 'inline-block' },

  variants: { direction: { row: { '@md': { flexDirection: 'row' } } } }
})
