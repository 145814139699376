const getLinkProps = ({ data } = {}, label) => {
  if (!data) return
  const {
    slug,
    parent,
    locale
  } = data.attributes

  let href = `/${slug}`
  if (parent) href = `/${parent}${href}`

  return {
    id: data.id,
    label,
    href,
    locale
  }
}


const getMenus = (data) => {
  if (!data) return []

  const { data: menuData } = data

  if (!menuData) return []

  return (Array.isArray(menuData) ? menuData : [menuData]).map(({
    attributes,
    id
  }) =>{
    const {
      urlIndeed,
      title,
      locale,
      slug,
      parent
    } = attributes

    let href

    if ('urlIndeed' in attributes) {
      href = urlIndeed
    } else {
      href = `/${slug}`
      if (parent) href = `/${parent}${href}`
    }

    return {
      id,
      label: title,
      href,
      locale,
    }
  })
}

const Footer = async (data) => {
  if (!data.data) return {}

  const { attributes } = data.data
  const {
    title,
    upperMenu,
    lowerMenu
  } = attributes

  const normalizedData = {
    payoff: title,
    upper: null,
    lower: null
  }

  if (upperMenu) {
    const upper = Object.keys(upperMenu)
      .filter(k => typeof upperMenu[k] === 'object' && upperMenu[k])
      .map(key => {
        const {
          title: label,
          page,
          relation,
          description,
          ...otherMenuProps
        } = upperMenu[key]

        const header = getLinkProps(page, label) || null
        let main = getMenus(relation)

        if (description) main = [{ description }, ...main]

        const formattedMenu = {
          ...otherMenuProps,
          header,
          main,
        }

        return formattedMenu
      })
      .sort((a, b) => a.order - b.order)

    Object.assign(normalizedData, { upper })
  }

  if (lowerMenu) {
    const lower = Object.keys(lowerMenu)
      .filter(k => typeof lowerMenu[k] === 'object' && lowerMenu[k])
      .map(key => {
        const main = lowerMenu[key].map(({
          title: label,
          url: href,
          ...otherMenuProps
        }) => ({
          label,
          href,
          ...otherMenuProps
        }))

        const lowerMenuFormatted = {
          className: 'LowerMenu',
          type: 'MenuItem',
          collapsable: false,
          direction: 'row',
          main,
        }

        return lowerMenuFormatted
      })

    const {
      company,
      vatNumber
    } = lowerMenu

    Object.assign(normalizedData, {
      lower,
      company,
      vatNumber
    })
  }

  return normalizedData
}

export default Footer
