import {
  styled, rem
} from 'config/stitches.config'

import Grid from '@/UI/Grid'

export const StyledStats = styled('div', {
  marginTop: rem(40),

  [`${Grid}`]: {
    columnGap: rem(132),
    rowGap: rem(48),
    '@md': { rowGap: rem(100) }
  }
})

export const StyledStat = styled('div', {
  'strong': {
    fontWeight: '$1',
    fontSize: 'clamp($stats-mobile, 7vw, $stats)',
    lineHeight: 'clamp($stats-mobile, 7vw, $stats)',
    fontVariantNumeric: 'tabular-nums'

    // fontSize: '$stats-mobile',
    // lineHeight: '$stats-mobile',
    // '@md': {
    //   fontSize: '7vw',
    //   lineHeight: '7vw',
    // },
    // '@xl': {
    //   fontSize: '$stats',
    //   lineHeight: '$stats',
    // }
  },

  'p': { color: '$gray ' }

})

export const StyledStatDetail = styled('div', {
  display: 'inline-flex',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%'
})
