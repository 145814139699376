import * as yup from 'yup'

export const createValidationSchema = (schema, config) => {
  const {
    name,
    validationType,
    validations = [],
    type: fieldType
  } = config

  if (!yup[validationType]) {
    return schema
  }

  let validator = yup[validationType]()
  if (validations && !!validations.length) {
    validations.forEach(({
      params = [],
      type
    }) => {
      if (!validator[type]) {
        return
      }
      if (fieldType !== 'file') {
        validator = validator[type](...params)
      } else {
        const fileValidation = {
          fileSize: (value) => value ? value.size <= Number(params[2]) : true,
          fileFormat: (value) => value ? params[2].includes(value.type) : true,
        }
        validator = validator[type](params[0], params[1], fileValidation[params[0]])
      }
    })
  }

  schema[name] = validator
  return schema
}

const Form = (data) => {
  const {
    form,
    ...restData
  } = data

  const clonedData = { ...form }


  if (!clonedData.data || !clonedData.data.attributes.fields) return restData

  const {
    fields,
    ...otherFormProps
  } = clonedData.data.attributes

  const initialValues = {}
  const {
    fieldsets,
    ...rest
  } = fields

  const normalizedInitialValues = {}

  const originalFieldset = JSON.parse(JSON.stringify(fieldsets))

  fieldsets.forEach(fieldset => {
    fieldset.fields.forEach(field => {
      normalizedInitialValues[field.name] = (field.value === null || field.value === undefined) ? '' : field.value
      if (initialValues[field.name]) normalizedInitialValues[field.name] = initialValues[field.name]

      delete field.value

      delete field.validationType
      delete field.validations
    })
  })

  const normalizedData = {
    ...otherFormProps,
    fields: {
      ...rest,
      initialValues: normalizedInitialValues,
      originalFieldset,
      fieldsets,
    },
  }

  return {
    ...restData,
    form: normalizedData
  }
}

export default Form
