import {
  styled, rem
} from 'config/stitches.config'

const Button = styled('button', {
  display: 'inline-flex',
  alignItems: 'center',
  columnGap: '$3',
  textDecoration: 'none',
  cursor: 'pointer',
  transition: 'background-color ease-in-out .4s, color ease-in-out .4s',
  fontSize: '$p2-mobile',
  lineHeight: '$p2-mobile',
  boxSizing: 'border-box',
  '&.no-hover': { cursor: 'auto' },
  variants: {
    size: {
      verySmall: {
        height: '$3',
        width: '$4',
        minWidth: '$4',
        paddingY: '$1/2',
        paddingX: '$1',
        br: '$small',
        '@md': {
          width: 'auto',
          height: rem(40),
        }
      },
      small: {
        letterSpacing: 0.7,
        paddingY: '$1',
        paddingX: '$2',
        br: '$small'
      },
      big: {
        paddingY: '$2',
        paddingX: '$3',
        br: '$big'
      },

    },
    theme: {
      default: {
        border: '1px solid $black',
        backgroundColor: '$black',
        color: '$white',
        // '&:not(.no-hover):hover': {
        //   backgroundColor: '$white',
        //   color: '$black',
        // },

      },
      inverted: {
        border: '1px solid $white',
        backgroundColor: '$white',
        color: '$black',
        // '&:not(.no-hover):hover': {
        //   backgroundColor: '$black',
        //   color: '$white',
        // },
      },
      reset: {
        backgroundColor: 'transparent',
        border: 'none',
        br: 0,
        padding: 0,
      },
      noBackground: {
        border: '1px solid $black',
        backgroundColor: 'transparent',
        color: '$black',
      }
    },
    disabled: {
      true: {
        pointerEvents: 'none',
        opacity: 0.15
      },
      false: { pointerEvents: 'auto' }
    }
  },
  defaultVariants: {
    theme: 'default',
    size: 'small'
  }

})

export default Button
