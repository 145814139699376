import {
  useState,
  useEffect
} from 'react'

import breakpoints from 'theme/breakpoints'

const useIsMobile = (breakpoint = 'md') => {
  const [isMobile, setIsMobile] = useState(undefined)

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth < breakpoints[breakpoint])
  }

  useEffect(() => {
    if (!!window) {
      checkIsMobile()
      window.addEventListener('resize', checkIsMobile)
    }
    return () => {
      if (window) {
        window.removeEventListener('resize', checkIsMobile)
      }
    }
  }, [])

  return isMobile
}

export default useIsMobile
