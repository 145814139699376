import React, { useState } from 'react'
import {
  motion,
  AnimatePresence
} from 'framer-motion'


import useIsMobile from 'hooks/useIsMobile'

import CustomImage from '@/UI/Image'
import Text from '@/UI/Text'

import {
  StyledListContainer,
  StyledList,
  StyledImageWithLabel,
  StyledImageWrapper,
  StyledLabel
} from './styles'

const ImageWithLabelRepeater = ({ items }) => {
  const [buttonHovered, setButtonHovered] = useState(null)
  const isMobile = useIsMobile()
  if (!items || items.length === 0) return <></>

  return (
    <StyledList data-lenis-prevent>
      <StyledListContainer>
        {
          items.map(({
            id,
            image,
            label
          }) => {
            return (
              <StyledImageWithLabel
                as={motion.div}
                key={id}
                layout
                onHoverStart={() => setButtonHovered(id)}
                onHoverEnd={() => setButtonHovered(null)}

              >
                {
                  image &&
								image.data &&
                <StyledImageWrapper as={motion.div} layout>
                  <CustomImage class="TinyImage" {...image} />
                </StyledImageWrapper >
                }

                <AnimatePresence>
                  {
                    (buttonHovered === id || isMobile) &&
                  <StyledLabel
                    as={motion.span}
                    initial={{
                      scaleX: 0,
                      opacity: 0
                    }}
                    animate={{
                      scaleX: 1, opacity: 1
                    }}
                    exit={{
                      scaleX: 0,
                      opacity: 0,
                      position: 'absolute',
                      transition: { duration: 0 }
                    }}
                    transition={{ duration: 0.4 }}
                  >
                    <Text
                      size='p3-mid'
                    >
                      {label}
                    </Text>

                  </StyledLabel>
                  }

                </AnimatePresence>
              </StyledImageWithLabel>
            )
          })
        }
      </StyledListContainer>
    </StyledList>
  )
}

export default ImageWithLabelRepeater
