import SimpleLink from '@/UI/Link'
import { styled, } from 'config/stitches.config'
import { motion } from 'framer-motion'

export const StyledNewsCarousel = styled('div', {})

export const StyledNewsCarouselList = styled('div', {
  display: 'block',
  height: 40,
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
})


export const StyledNewsCarouselListItem = styled(motion.div, {
  position: 'absolute',
  top: '50%',
  left: 0,
  transform: 'translateY(-50%)',
  size: '100%',
  display: 'flex',
  alignItems: 'center',
})

export const StyledNewsCarouselListContent = styled(SimpleLink, {
  textDecoration: 'none',
  color: '$black',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: '$1'
})
