const Tabs = async (data) => {
  const {
    tabsFields,
    ...restData
  } = data

  if (!tabsFields) return data

  const normalizedTabsFields = tabsFields.reduce((acc, curr) =>{
    const {
      id,
      title,
      items
    } = curr

    acc.header.push({
      id, title, totalItems: items.length
    })

    acc.content.push({
      id,
      items
    })

    return acc
  }, {
    header: [], content: []
  })

  const normalizedData = {
    ...restData,
    tabsFields: normalizedTabsFields
  }

  return normalizedData
}

export default Tabs
