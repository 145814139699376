import React from 'react'

import StatsDetail from './StatsDetail'

import Grid from '@/UI/Grid'
import Text from '@/UI/Text'

import {
  StyledStats,
  StyledStat
} from './styles'

const Stats = ({ items }) => {
  return (
    items &&
    !!items.length &&
    <StyledStats>
      <Grid columns={{
        '@initial': 1,
        '@md': 2
      }}>
        {
          items.map(({
            id,
            title,
            subtitle
          }) => {
            return (
              <StyledStat key={id}>
                <StatsDetail content={title}/>
                <Text as="p" size={{
                  '@initial': 'subtitle-2-mobile',
                  '@md': 'subtitle-2',
                }}>{subtitle}</Text>

              </StyledStat>
            )
          })}
      </Grid>


    </StyledStats>
  )
}

export default Stats
