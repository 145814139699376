const colors = {
  orange: '#E17020',
  black: '#141414',
  darkGray: '#4D4D4F',
  gray: '#686868',
  lightGray: '#CBCBCB',
  accessibleLightGray: '#AAA',
  accessibleGray: '#707070',
  background: '#FAFAFA',
  white: '#FFFFFF',
  pictureBackground: '#EEEEEE',
  tag: '#E7E7E7',
  error: '#EC4040',
  success: '#58A44C',
}

export default colors
