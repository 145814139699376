import React, { useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import {
  Formik,
  Form
} from 'formik'

import { fetchData } from 'services/API'

import ButtonTimer from '@/Buttons/ButtonTimer'

import RichText from '@/UI/RichText'
import Cta from '@/UI/Cta/DefaultCta'

import Fieldset from './Fieldset'

import { StyledFormSubmit } from './styles'

const FormModal = dynamic(() => import('@/Modals/FormModal'), { ssr: false, })

const CustomForm = ({
  endpoint,
  successMessage,
  initialValues,
  fieldsets = [],
  validationSchema = {},
  validateOnMount = false,
  submitButton = null,
  onSubmit = null,
  className = null,
  darkMode = false,
  settings = null
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { locale } = useRouter()

  const getErrors = (errors) => {
    const { details: { errors: errorsArray }  } = errors
    return errorsArray.reduce((acc, curr) => {
      const {
        path, message
      } = curr

      acc[path[0]] = message
      return acc
    }, {})
  }

  return (
    <>
      <Formik
        validateOnMount={validateOnMount}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={async (values, {
          resetForm,
          setErrors,
        }) => {
          if (endpoint) {
            const urlEndpoint = new URL(endpoint)
            urlEndpoint.searchParams.set('locale', locale)

            const formData = new FormData()

            for (const val in values) {
              formData.append(val, values[val])
            }

            const response = await fetchData(urlEndpoint, {
              method: 'POST',
              body: formData
            })

            if (response.error) {
              const errors = await getErrors(response.error)
              await setErrors(errors)
              return
            }
          }

          await resetForm()
          setIsOpen(true)

          if (onSubmit) await onSubmit(values)
        }}
      >
        {({
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Form className={className}>
            {
              fieldsets &&
              !!fieldsets.length &&
              fieldsets.map((fieldset, idx) => <Fieldset key={idx} {...fieldset} />)
            }

            {
              submitButton &&
              <StyledFormSubmit>
                <Cta
                  className="FormSubmit"
                  as="button"
                  type="submit"
                  disabled={!isValid || !!Object.entries(errors).length || isSubmitting}
                  darkMode={darkMode}
                  size="big"
                  {...submitButton}
                >
                  {
                    submitButton.label &&
                  <span>
                    {submitButton.label}
                  </span>
                  }
                </Cta>
              </StyledFormSubmit>
            }
          </Form>
        )}
      </Formik>

      {
        settings &&
        !settings.hideSuccessMessage &&
         <FormModal
           isOpen={isOpen}
           modalHandler={() => setIsOpen(false)}
         >
           <RichText
             className="SuccessMessage"
             size={{
               '@initial': 'p1-mid-mobile',
               '@md': 'p1-mid',
             }}
             content={successMessage}
           />
           <ButtonTimer
             onClick={() => setIsOpen(false)}
             onAnimationComplete={() => {
               if (isOpen) setIsOpen(false)
             }}
           />
         </FormModal>
      }

    </>
  )
}

export default CustomForm
