import React from 'react'
import { useField  } from 'formik'

import CustomField from './index'
import { StyledInputFileLabel } from './styles'
const FileInput = ({
  label,
  placeholder,
  ...props
}) => {
  const [field, meta, helpers] = useField(props)

  const onChange = (e) =>{
    if (e.currentTarget.files[0]) helpers.setValue(e.currentTarget.files[0])
  }

  return (
    <CustomField
      id={props.id}
      type={props.type}
      label={label}
      field={field}
      meta={meta}
    >
      <StyledInputFileLabel tabIndex={0}>
        {
          !!field.value && field.value.name
            ? field.value.name
            : placeholder
        }
      </StyledInputFileLabel>

      <input id={field.name} {...field} {...props} title={label}  value={undefined}  onChange={onChange}/>
    </CustomField>
  )
}

export default FileInput
