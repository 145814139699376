import React from 'react'

import SectionContainer from '@/Section/Container'

import Jobs from '@/Jobs'

const JobsList = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) => {
  return (
    <SectionContainer
      theme={theme}
      sectionHeader={sectionHeader}
    >
      <Jobs {...sectionContent} />

    </SectionContainer>
  )
}

export default JobsList
