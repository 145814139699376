import React from 'react'
import Heading from '@/UI/Heading'

import Input from './Input'

import {
  StyledRadioGroup,
  StyledRadioGroupContainer
} from './styles'

const Radio = ({
  label,
  items,
}) => {
  return (
    <StyledRadioGroup>
      {
        label &&
        <Heading as='strong' size="s2">{label}</Heading>
      }
      <StyledRadioGroupContainer>
        {
          items.map((item, index) => (<Input key={index} {...item}  />))
        }
      </StyledRadioGroupContainer>
    </StyledRadioGroup>
  )
}

export default Radio
