import {
  styled,
  rem,
} from 'config/stitches.config'

import Cta from '@/UI/Cta/DefaultCta'

Cta.toString = () => '.FormSubmit'

export const StyledFieldset = styled('fieldset', {
  all: 'unset',
  display: 'block',
  width: '100%',
  borderColor: 'transparent',
  margin: `0 0 ${rem(64)} 0`,
  padding: 0,
})

export const StyledFieldsetTitle = styled('div', {
  display: ' block',
  marginBottom: '$3',
  'strong': {}
})

export const StyledFieldsetContent = styled('div', {})

export const StyledFormSubmit = styled('div', {
  [' .FormSubmit']: {
    width: '100%',
    justifyContent: 'space-between',
    '@md': { width: 'auto', }
  },
})
