import Footer from './Footer'
import Form from './Form'
import JobsList from './JobsList'
import MainMenu from './MainMenu'
import Options from './Options'
import PartnersList from './PartnersList'
import Scenarios from './Scenarios'
import SectionContact from './SectionContact'
import ServicesList from './ServicesList'
import Tabs from './Tabs'
import NewsItemsArchive from './NewsItemsArchive'
import NewsItems from './NewsItems'
import Hero from './Hero'

const ScenariosListPrefilled = Scenarios
const ServicesListPrefilled = ServicesList

export const models = {
  Footer,
  Form,
  JobsList,
  MainMenu,
  Options,
  PartnersList,
  Scenarios,
  SectionContact,
  ScenariosListPrefilled,
  ServicesList,
  ServicesListPrefilled,
  Tabs,
  NewsItemsArchive,
  NewsItems,
  Hero,
}
